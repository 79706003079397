import DetailsResource from './details-resource';
import PaymentSessionResource from './payment-session-resource';

const ACCOUNT_HOLDER_URL = '/account-holder';

export default function AccountHolderResources({ base, fundingURL }) {
  return {
    Details: DetailsResource({ base, fundingURL, accountHolderUrl: ACCOUNT_HOLDER_URL }),
    PaymentSession: PaymentSessionResource({ base, fundingURL, accountHolderUrl: ACCOUNT_HOLDER_URL }),
  };
}
