import { initializeApp, type FirebaseApp } from 'firebase/app';
import { browserSessionPersistence, initializeAuth, onAuthStateChanged, signInWithCustomToken, signOut, type User, type Auth } from 'firebase/auth';
import { getFirestore, LogLevel, setLogLevel, type Firestore } from 'firebase/firestore';
import { reactive, watch } from 'vue';

import { isProduction } from '@exchange/helpers/environment';
import { type ChartData } from '@exchange/libs/charting/service/src';
import oAuthRest from '@exchange/libs/rest-api/oauth2';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger, nonProdConsoleInfo } from '@exchange/libs/utils/simple-logger/src';

import { collectionAccessor, GetConverter, getUserString, type CollectionAccessorResult } from './collectionAccessor';
import { getSettingsConverter, getChartsConverter } from './convertors';

class FirebaseService {
  private readonly logPrefix = 'FirebaseService: ';

  private readonly firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJ_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
  };

  private app: FirebaseApp | undefined;

  private auth: Auth | undefined;

  private db: Firestore | undefined;

  private settingsCollection: CollectionAccessorResult<Dictionary<unknown>> | undefined;

  private chartsCollection: CollectionAccessorResult<ChartData> | undefined;

  private appGet = () => {
    if (this.app) {
      return this.app;
    }

    return this.appInit();
  };

  private appInit = () => {
    const app = initializeApp(this.firebaseConfig);

    this.app = app;
    return app;
  };

  private authGet = () => {
    if (this.auth) {
      return this.auth;
    }

    return this.authInit();
  };

  private authInit = () => {
    const app = this.appGet();
    const auth = initializeAuth(app, {
      /** https://firebase.google.com/docs/auth/web/custom-dependencies */
      persistence: browserSessionPersistence /** https://firebase.google.com/docs/auth/web/auth-state-persistence */,
    });

    onAuthStateChanged(
      auth,
      (user) => {
        if (user) {
          /** User is signed in, see docs for a list of available properties https://firebase.google.com/docs/reference/js/firebase.User */
          logger.info(`${this.logPrefix}user is signed in: ${user.uid}`);
          this.loginData.finished = true;
        } else {
          /** User is signed out */
          logger.info(`${this.logPrefix}user is signed out`);
          this.loginData.finished = false;
        }
      },
      (error) => {
        logger.info(`${this.logPrefix}on auth state changed error`, error);
      },
    );

    this.auth = auth;

    return auth;
  };

  private dbGet = () => {
    if (this.db) {
      return this.db;
    }

    return this.dbInit();
  };

  private dbInit = () => {
    const app = this.appGet();
    const db = getFirestore(app);

    this.db = db;

    return db;
  };

  private initSettingsCollection = () => {
    const db = this.dbGet();

    const ca = collectionAccessor<Dictionary<unknown>>(
      'settings',
      getSettingsConverter,
    )({
      db,
      logPrefix: this.logPrefix,
      getCurrentUserUID: this.getCurrentUserUID,
    });

    this.settingsCollection = ca;

    return ca;
  };

  public getSettingsCollection = () => {
    if (this.settingsCollection) {
      return this.settingsCollection;
    }

    return this.initSettingsCollection();
  };

  private initChartsCollection = () => {
    const db = this.dbGet();

    const ca = collectionAccessor<ChartData>(
      'charts',
      getChartsConverter as GetConverter<ChartData>,
    )({
      db,
      logPrefix: this.logPrefix,
      getCurrentUserUID: this.getCurrentUserUID,
    });

    this.chartsCollection = ca;

    return ca;
  };

  public getChartsCollection() {
    if (this.chartsCollection) {
      return this.chartsCollection;
    }

    return this.initChartsCollection();
  }

  private loginData = reactive({
    finished: false,
  });

  constructor(logLevel: LogLevel = 'error') {
    setLogLevel(logLevel);
    this.appGet();
    this.dbInit();
  }

  // eslint-disable-next-line consistent-return
  private waitForCurrentUser = (auth: Auth) =>
    new Promise<User>((resolve, reject) => {
      if (auth.currentUser) {
        resolve(auth.currentUser);
        return;
      }

      // eslint-disable-next-line consistent-return
      const watchStopHandle = watch(
        () => this.loginData.finished,
        (finished) => {
          if (finished) {
            watchStopHandle();

            if (auth.currentUser) {
              resolve(auth.currentUser);
              return;
            }

            reject(new Error(`${this.logPrefix}user is not found`));
          }
        },
      );
    });

  private getCurrentUserUID = (name: string) => async () => {
    const auth = this.authGet();
    const currentUser = await this.waitForCurrentUser(auth);

    if (!currentUser.uid) throw new Error(`${this.logPrefix}${name} - User not authenticated`);

    return currentUser.uid;
  };

  private getFirebaseToken = async () => {
    try {
      const { access_token: token } = await oAuthRest.FirebaseResource.get();

      return token;
    } catch (e) {
      await retryService.waitForNextRetryTick();
      return this.getFirebaseToken();
    }
  };

  private signInWithToken = async () => {
    const token = await this.getFirebaseToken();
    const auth = this.authGet();

    const { user } = await signInWithCustomToken(auth, token);

    return user;
  };

  public login = async () => {
    const auth = this.authGet();

    nonProdConsoleInfo(`${this.logPrefix}login; user: ${auth.currentUser ? getUserString(auth.currentUser) : 'there is no currentUser'}`);

    if (auth.currentUser) {
      const deserializedJWT = await auth.currentUser.getIdTokenResult(true);

      nonProdConsoleInfo(`${this.logPrefix}user is already set; token iat: ${deserializedJWT.issuedAtTime}, token exp: ${deserializedJWT.expirationTime}`);
      return;
    }

    await this.signInWithToken();

    nonProdConsoleInfo(`${this.logPrefix}login done;`, `${auth.currentUser ? getUserString(auth.currentUser) : 'there is no currentUser'}`);
  };

  public logout = async () => {
    this.getSettingsCollection().removeSubscription();
    const auth = this.authGet();

    await signOut(auth);

    nonProdConsoleInfo(`${this.logPrefix}logout done`);
  };
}

const firebaseService = new FirebaseService(isProduction ? 'error' : 'verbose');

export default firebaseService;
