import rest from '@exchange/libs/utils/rest/src';

const URL = '/fees';

export default function FeesResource({ base }) {
  return {
    get() {
      return rest.unAuthorizedRequest({
        url: URL,
        base,
      });
    },
  };
}
