<script lang="ts" setup>
import { ref } from 'vue';

const updateRibbonEl = ref<HTMLElement>();
const requestAppUpdate = () => {
  const event = new CustomEvent('app-update-requested');

  updateRibbonEl.value?.dispatchEvent(event);
};
</script>

<template>
  <div
    ref="updateRibbonEl"
    class="update-ribbon"
  >
    <p class="update-ribbon__body">
      <span>{{ $t('modules.ribbons.newVersion.text') }}</span>
      <button
        class="update-ribbon__action"
        @click="requestAppUpdate"
      >
        {{ $t('modules.ribbons.newVersion.button') }}
      </button>
    </p>
  </div>
</template>

<style lang="scss" scoped>
.update-ribbon {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  background-color: rgb(var(--v-theme-primary));
  color: var(--text-0);

  &__body {
    padding: 0;
    border: 0;
    background-color: transparent;
    color: var(--text-0);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);

    .sm &,
    .xs & {
      margin: 0 50px 0 20px;
      text-align: center;
    }
  }

  &__action {
    color: var(--text-0);
    font-weight: inherit;
    text-decoration: underline;
  }
}
</style>
