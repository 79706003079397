import BigNumber from '@exchange/helpers/bignumber';
import { OrderSide } from '@exchange/libs/order/shared-model/src/lib/order-essentials';

import { OrderbookSide, OrderbookUpdateOperations } from './interfaces';
import type { PriceLevel } from './interfaces';

/**
 * Orderbook optimized search
 */
export function findIndex<T extends PriceLevel>(priceToFind: number, array: Array<T>, side: OrderbookSide): { index: number; exists: boolean } {
  // IF array is empty return 0
  if (!array.length) {
    return {
      index: 0,
      exists: false,
    };
  }

  // IF priceToFind is bigger or Equal to first Price - return index 0 (add/update/remove)
  const fl = array[0];

  const checkPriceFirst = (level: PriceLevel) => (side === 'ASK' ? priceToFind <= level.price : priceToFind >= level.price);

  if (fl && checkPriceFirst(fl)) {
    return {
      index: 0,
      exists: fl.price === priceToFind,
    };
  }

  const lastIndex = array.length - 1;
  const ll = array[lastIndex];

  const checkPriceLast = (level: PriceLevel) => (side === 'ASK' ? priceToFind > level.price : priceToFind < level.price);

  // IF priceToFind is smaller than last price - return index after last item (add)
  if (ll && checkPriceLast(ll)) {
    return {
      index: lastIndex + 1,
      exists: false,
    };
  }

  // IF priceToFind is Equal to last Price return that index (update/remove)
  if (ll && ll.price === priceToFind) {
    return {
      index: lastIndex,
      exists: true,
    };
  }

  // BTree search:
  let lookIndex: number;
  let leftBound = 0;
  let rightBound = lastIndex;

  for (; rightBound - leftBound > 1; ) {
    // Look at the middle (or slightly left of it) between leftbound and rightbound
    lookIndex = Math.floor(leftBound + (rightBound - leftBound) / 2);
    const lkl = array[lookIndex];

    // IF We found the price - return it
    if (lkl && lkl.price === priceToFind) {
      return {
        index: lookIndex,
        exists: true,
      };
    }

    if (side === 'ASK') {
      if (lkl && priceToFind < lkl.price) {
        rightBound = lookIndex;
      } else {
        leftBound = lookIndex;
      }
    } else if (lkl && priceToFind > lkl.price) {
      rightBound = lookIndex;
    } else {
      leftBound = lookIndex;
    }
    // if the price we found is to small - move the rightBound into the current Middle
  }

  // We did not find the Element - so inset at the Index
  return {
    index: leftBound === rightBound ? rightBound + 1 : rightBound,
    exists: false,
  };
}

export function calculateAggregation({ price, side, aggregationLevel }: { price: number; side: OrderbookSide | OrderbookUpdateOperations | OrderSide; aggregationLevel: number }) {
  const roundMode = side === OrderbookUpdateOperations.BUY || side === OrderbookSide.BID || side === OrderSide.BUY ? BigNumber.ROUND_DOWN : BigNumber.ROUND_UP;

  const power = new BigNumber(1).times(aggregationLevel).toNumber();
  const moveCommaTo = new BigNumber(10).pow(power);

  return new BigNumber(price).div(moveCommaTo).integerValue(roundMode).times(moveCommaTo).toNumber();
}
