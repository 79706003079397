import { MarketTickerMessage, WSSMarketTickerSubscribeChannel } from '@exchange/libs/market/service/src';
import { ChannelListenerTemplate, ChannelTemplate } from '@exchange/libs/utils/wss/src';
import {
  WSChannelNames,
  WSIncomingEventTypes,
  WSOutgoingEventTypesSpecial,
  wsSportChannelNames,
  type WSOutgoingSubscribeMessagePayload,
} from '@exchange/libs/utils/wss/src/lib/websocket-spot-model';

export interface MarketTickerChannelListener extends ChannelListenerTemplate<MarketTickerMessage> {
  markets: Array<string>;
}

export class MarketTickerChannel extends ChannelTemplate<MarketTickerMessage, MarketTickerChannelListener> {
  protected getSubscribeMessage(listener: MarketTickerChannelListener) {
    const channel: WSSMarketTickerSubscribeChannel = {
      name: wsSportChannelNames.MARKET_TICKER,
      price_points_mode: 'INLINE',
      instrument_codes: listener.markets,
    };
    const subscribeMessage: WSOutgoingSubscribeMessagePayload = {
      type: WSOutgoingEventTypesSpecial.SUBSCRIBE,
      channels: [channel],
    };

    return subscribeMessage;
  }

  protected get channelName(): WSChannelNames {
    return wsSportChannelNames.MARKET_TICKER;
  }

  protected override messageFilter({ event, listener }: { event: MarketTickerMessage; listener: MarketTickerChannelListener }) {
    return (
      super.messageFilter({ event, listener }) ||
      // MARKET UPDATES are on SYSTEM channel
      (event.channel_name === wsSportChannelNames.SYSTEM && event.type === WSIncomingEventTypes.MARKET_UPDATES)
    );
  }
}

export default new MarketTickerChannel({ useBunManager: true });
