import { PaymentSession } from '@exchange/libs/transactions/funds/service/src/lib/models/funding-payment-session';
import rest from '@exchange/libs/utils/rest/src';

const PAYMENT_SESSION_URL = '/payment-session';

export default function PaymentSessionResource({ base, fundingURL, accountHolderUrl }) {
  return {
    create(currencyCode: string, amount: number, baseUrl: string, locale: string): Promise<PaymentSession> {
      return rest.request({
        url: `${fundingURL}${accountHolderUrl}${PAYMENT_SESSION_URL}`,
        method: 'post',
        payload: { currency: currencyCode, amount, baseUrl, locale },
        base,
      });
    },
  };
}
