import CoinInfo from './coin-info-resource';
import Conversions from './conversions-resource';
import GeoInfo from './geo-info-resource';
import GetPriceChange from './get-price-change';
import GetPrices from './get-prices';

const API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
const API_SUFFIX = 'crypto-info';
const API_VERSION = 'v1';
const API_BASE = `${API_DOMAIN}/${API_SUFFIX}/${API_VERSION}`;

export const cryptoRest = {
  CoinInfo: CoinInfo({ base: API_BASE }),
  Conversions: Conversions({ base: API_BASE }),
  GeoInfo: GeoInfo({ base: API_BASE }),
  Prices: GetPrices({ base: API_BASE }),
  PriceChange: GetPriceChange({ base: API_BASE }),
};

export default cryptoRest;
