<script lang="ts" setup>
import { type PropType } from 'vue';

import { modalType, type ModalType, modalVariant, type ModalVariant } from './modal-type-validators';

defineProps({
  title: String,
  canClose: { type: Boolean, default: true },
  type: { type: String as PropType<ModalType>, default: modalType.base },
  variant: { type: String as PropType<ModalVariant>, default: modalVariant.light },
});
const emit = defineEmits<{
  (e: 'closed'): void;
}>();

const close = () => {
  emit('closed');
};
</script>

<template>
  <div
    :class="['modal-header', `modal-header--${type}`, `modal-header--${variant}`, { 'modal-header--closable': canClose }]"
    :testid="`modal-header-${type}`"
  >
    <div
      v-if="title"
      class="modal-header__title"
    >
      {{ title }}
    </div>
    <slot
      v-else
      name="default"
    />
    <button
      v-if="canClose"
      class="modal-header__close"
      testid="modal-close"
      @click="close"
    >
      <ot-icon
        class="modal-header__close-icon"
        name="close"
        inherit-stroke="inherit-stroke"
      />
    </button>
  </div>
</template>

<style lang="scss">
.modal-header {
  --m-header-padding-x: var(--modal-padding-x, 16px);
  --modal-header-color: var(--text-0);

  position: relative;
  min-height: min-content;
  padding: 16px var(--m-header-padding-x) 0;
  color: var(--modal-header-color);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-medium);
  letter-spacing: 0.15px;
  line-height: 160%;

  &__close {
    position: absolute;
    top: 10px;
    right: 20px;
    padding: 10px;
    color: var(--modal-header-color);

    .xs & {
      right: 8px;
    }

    &-icon {
      width: 16px;
      height: 16px;
    }
  }
}

.modal-header--light {
  --modal-header-color: var(--text-0);
}

.modal-header--dark {
  --modal-header-color: rgb(var(--v-theme-text-primary));
}

.modal-header--closable {
  .xs & {
    /* prevent title of overflowing close button */
    padding-right: calc(var(--m-header-padding-x) * 2.3);
  }
}
</style>
