import type { FundingPayoutAccountsResponse, FundingPayoutAccountSingleResponse, FundingTransferMethodType } from '@exchange/libs/transactions/funds/service/src';
import rest from '@exchange/libs/utils/rest/src';

const payoutAccountsURL = '/account-holder/payout-accounts';

export default function PayoutAccountsResource({ base, fundingURL }) {
  const url = `${fundingURL}${payoutAccountsURL}`;

  return {
    async get(transferMethod?: FundingTransferMethodType): Promise<FundingPayoutAccountsResponse> {
      const response = await rest.request({
        url: transferMethod ? `${url}/${transferMethod}` : url,
        base,
      });

      return response;
    },

    async create(payload: Dictionary<string>): Promise<FundingPayoutAccountSingleResponse> {
      const response = await rest.request({
        method: 'post',
        payload,
        url: `${url}/${payload.type}`,
        base,
      });

      return response;
    },

    delete(payoutAccountId: string): Promise<void> {
      return rest.request({
        method: 'delete',
        url: `${url}/${payoutAccountId}`,
        base,
      });
    },
  };
}
