import BigNumber from '@exchange/helpers/bignumber';
import getTimestampNumber from '@exchange/helpers/date-to-milliseconds-since-epoch';
import getBaseQuote from '@exchange/helpers/get-base-quote';
import getPrecision from '@exchange/helpers/get-precision';
import percentFormatting from '@exchange/helpers/percent-formatting';
import { TradeModel } from '@exchange/libs/trade/trade-history/service/src';
import type { BETradeHistoryEntry } from '@exchange/libs/trade/trade-history/service/src';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import { finalStatuses, OrderType, OrderSide, MyOrdersListType, MyOrdersTabs, OrderStatus, OrderStatusFastToLegacy, OrderAnimation, TimeInForceType } from './order-essentials';
import type { BEOrderSnapshotModel, InMyOrderType } from './order-essentials';

export interface OrderTradesPair {
  order: BEOrderSnapshotModel;
  trades: Array<BETradeHistoryEntry>;
}

type UpdateReactiveOrderDataType = (orderId: string, myOrdersType: InMyOrderType) => void;

const getFee = (trades: Array<TradeModel>, best?: string): BigNumber =>
  trades.reduce((sum, trade) => {
    if (best) {
      return sum.plus(trade.collectionType === CONSTANTS.BEST_CODE ? trade.feeAmount : 0);
    }

    return sum.plus(trade.collectionType === CONSTANTS.BEST_CODE ? 0 : trade.feeAmount);
  }, new BigNumber(0));

const getDisplayAmount = (array: Array<TradeModel>, amount: BigNumber | number) => {
  if (array.length) {
    return amount;
  }

  return NaN;
};

export class OrderWithTradesModel {
  private readonly REMOVE_DELAY = 60 * 1000;

  private readonly showAsMap = {
    // Active
    [OrderStatus.OPEN]: MyOrdersTabs.ACTIVE,
    [OrderStatus.STOP_TRIGGERED]: MyOrdersTabs.ACTIVE,
    [OrderStatus.FILLED]: MyOrdersTabs.ACTIVE,
    [OrderStatus.CLOSING]: MyOrdersTabs.ACTIVE,
    // Inactive
    [OrderStatus.FILLED_CLOSED]: MyOrdersTabs.INACTIVE,
    [OrderStatus.FILLED_FULLY]: MyOrdersTabs.INACTIVE,
    [OrderStatus.FILLED_REJECTED]: MyOrdersTabs.INACTIVE,
    // Hidden
    [OrderStatus.CLOSED]: MyOrdersTabs.HIDDEN,
    [OrderStatus.REJECTED]: MyOrdersTabs.HIDDEN,
    [OrderStatus.FAILED]: MyOrdersTabs.HIDDEN,
  };

  private readonly showAsToMOList = {
    [MyOrdersTabs.ACTIVE]: MyOrdersListType.open,
    [MyOrdersTabs.INACTIVE]: MyOrdersListType.filled,
    [MyOrdersTabs.HIDDEN]: undefined,
  };

  public amount!: BigNumber;

  public animation: OrderAnimation = OrderAnimation.none;

  public averagePrice: BigNumber | undefined;

  public bestFeeAmount!: BigNumber;

  public delayedRemove = false;

  public fallBackReason!: boolean;

  public feeAmount!: BigNumber;

  public feeCurrency!: string;

  public filledAmount!: BigNumber;

  public id!: string;

  public instrumentCode!: string;

  public base!: string;

  public quote!: string;

  public internalStatus!: OrderStatus;

  public myOrdersType: InMyOrderType = {
    before: [MyOrdersListType.open],
    now: [MyOrdersListType.open],
  };

  public price?: number;

  public sequence!: number;

  public side!: OrderSide;

  public time!: Date;

  public timeNano: number;

  public timeInForce?: TimeInForceType;

  public timeLastUpdated?: Date;

  public timestampLastUpdated?: string;

  public timeTriggered?: Date;

  public total!: BigNumber;

  public trades: Array<TradeModel> = [];

  public triggerPrice?: number;

  public type!: OrderType;

  public updateSequence!: number;

  public updateReactiveOrderData!: UpdateReactiveOrderDataType;

  public animationUpdateWithCache!: (animation: OrderAnimation, status: OrderStatus) => void;

  public get isFullyFilled() {
    return this.filledAmount.eq(this.amount);
  }

  public get hasFills() {
    return this.filledAmount.gt(0);
  }

  public get displayPrice() {
    if (this.type === OrderType.MARKET) {
      return NaN;
    }

    if (this.price !== undefined) {
      return new BigNumber(this.price);
    }

    return undefined;
  }

  public get priceSuffix() {
    if (this.isMarketOrder) {
      return '';
    }

    return this.quoteCurrencyCode;
  }

  get amountSuffix() {
    return this.baseCurrencyCode;
  }

  public get displayFilledAmount() {
    if (this.hasFills) {
      return this.filledAmount;
    }

    return NaN;
  }

  public get displayTotal() {
    return OrderWithTradesModel.getTotalToDisplay(this.type, this.amount, this.total, this.price, this.trades);
  }

  public get displayTradesNumber() {
    const { length } = this.trades || [];

    if (length >= 100) {
      return '≥ 100';
    }

    return length.toString();
  }

  public get displayFeeAmount() {
    return getDisplayAmount(this.trades, this.feeAmount);
  }

  public get displayBestFeeAmount() {
    return getDisplayAmount(this.trades, this.bestFeeAmount);
  }

  public get hasFees() {
    return new BigNumber(this.displayFeeAmount).gt(0);
  }

  public get hasBestFees() {
    return new BigNumber(this.displayBestFeeAmount).gt(0);
  }

  public get hasBothFees() {
    return this.hasFees && this.hasBestFees;
  }

  public get standardFeeCurrencyCode() {
    return this.feeCurrency;
  }

  public get baseCurrencyCode() {
    return this.base;
  }

  public get quoteCurrencyCode() {
    return this.quote;
  }

  get marketName() {
    return `${this.base}/${this.quote}`;
  }

  get isBuy() {
    return this.side === OrderSide.BUY;
  }

  get isMarketOrder() {
    return this.type === OrderType.MARKET;
  }

  get isStopOrder() {
    return this.type === OrderType.STOP;
  }

  public get filledPercent(): string {
    const bigV = this.filledAmount.div(this.amount).times(100);

    return percentFormatting(bigV);
  }

  public get isCancelable() {
    return this.type !== OrderType.MARKET && this.showAsMap[this.status] === MyOrdersTabs.ACTIVE;
  }

  public get displayedTimeInForce(): string | undefined {
    if (this.type === OrderType.LIMIT && this.timeInForce !== TimeInForceType.GOOD_TILL_CANCELLED) {
      return this.timeInForce;
    }

    return undefined;
  }

  public get status(): OrderStatus {
    return this.internalStatus;
  }

  public set status(status: OrderStatus) {
    if (this.showAsMap[this.internalStatus] === MyOrdersTabs.ACTIVE && this.showAsMap[status] !== MyOrdersTabs.ACTIVE) {
      this.delayedRemove = true;
      setTimeout(() => {
        this.delayedRemove = false;

        if (this.updateReactiveOrderData) {
          this.setMyOrdersType();
          this.updateReactiveOrderData(this.id, this.myOrdersType);
        }
      }, this.REMOVE_DELAY);
    }

    this.internalStatus = status;
  }

  public get showAs(): MyOrdersTabs {
    if (!this.showAsMap[this.status]) {
      logger.warn('Unknown Status', this.status);
    }

    return this.showAsMap[this.status];
  }

  constructor({ order, trades }: OrderTradesPair, updateReactiveOrderData: UpdateReactiveOrderDataType) {
    this.updateReactiveOrderData = updateReactiveOrderData;
    this.id = order.order_id;
    this.time = new Date(getTimestampNumber(order.time));
    this.timeNano = Number(order.time_nano ?? 0);
    this.sequence = order.sequence;
    this.updateSequence = order.update_modification_sequence ?? -1;

    this.instrumentCode = order.instrument_code;
    this.type = order.type;
    this.side = order.side;

    this.price = order.price ? +order.price : undefined;
    this.triggerPrice = order.trigger_price ? +order.trigger_price : undefined;
    this.averagePrice = order.average_price ? new BigNumber(order.average_price) : undefined;
    this.timeTriggered = order.time_triggered ? new Date(getTimestampNumber(order.time_triggered)) : undefined;
    this.timeLastUpdated = order.time_last_updated ? new Date(getTimestampNumber(order.time_last_updated)) : undefined;

    this.timeInForce = order.time_in_force;

    this.timestampLastUpdated = order.time_last_updated;

    this.amount = new BigNumber(order.amount ?? 0);
    this.filledAmount = new BigNumber(order.filled_amount ?? 0);

    this.status = OrderStatusFastToLegacy[order.status] as OrderStatus;

    this.total = this.amount.times(order.average_price ?? 0);

    this.trades = trades.map((tradeFeePair) => new TradeModel(tradeFeePair));

    this.fallBackReason = this.hasFallbackReason();

    const { base, quote } = getBaseQuote(this.instrumentCode, '_');

    this.base = base;
    this.quote = quote;

    if (order.total_fee !== undefined && order.fee_currency !== undefined) {
      this.feeAmount = new BigNumber(order.total_fee ?? 0);
      this.bestFeeAmount = new BigNumber(0);
      this.feeCurrency = order.fee_currency;
    } else {
      this.feeAmount = getFee(this.trades);
      this.bestFeeAmount = getFee(this.trades, 'best');
      this.feeCurrency = this.side === OrderSide.BUY ? this.base : this.quote;
    }

    this.animationUpdateWithCache = (() => {
      const animations = new Set<OrderStatus>();

      return (animation: OrderAnimation, status: OrderStatus) => {
        if (!animations.has(status)) {
          this.animationUpdate(animation);
          animations.add(status);
        }
      };
    })();
  }

  static getTotalToDisplay(type: OrderType, amount: BigNumber, total: BigNumber, price?: number, trades?: TradeModel[], actualNumber = true) {
    if (type === OrderType.MARKET && +total) {
      return total;
    }

    if (trades && trades.length && actualNumber) {
      return trades.reduce((sum, trade) => sum.plus(trade.total), new BigNumber(0));
    }

    if (price) {
      return amount.times(price);
    }

    return NaN;
  }

  public setMyOrdersType() {
    this.myOrdersType.before = this.myOrdersType.now;

    switch (this.showAsToMOList[this.showAs]) {
      case MyOrdersListType.open: {
        this.myOrdersType.now = [MyOrdersListType.open];
        break;
      }
      case MyOrdersListType.filled: {
        if (this.delayedRemove) {
          this.myOrdersType.now = [MyOrdersListType.open, MyOrdersListType.filled];
          break;
        }

        this.myOrdersType.now = [MyOrdersListType.filled];
        break;
      }
      case undefined: {
        if (this.delayedRemove) {
          this.myOrdersType.now = [MyOrdersListType.open];
          break;
        }

        this.myOrdersType.now = undefined;
        break;
      }
      default: {
        this.myOrdersType.now = undefined;
      }
    }

    return this.myOrdersType;
  }

  private animationUpdate(animation: OrderAnimation) {
    this.animation = animation;
  }

  private animationShouldChange(status: OrderStatus) {
    return [OrderStatus.FILLED_FULLY, OrderStatus.STOP_TRIGGERED, OrderStatus.REJECTED, OrderStatus.FAILED, OrderStatus.FILLED_REJECTED].includes(status);
  }

  public animationPlay(status: OrderStatus, listType: MyOrdersListType) {
    if (listType === MyOrdersListType.filled) {
      this.animationUpdate(OrderAnimation.none);
      return;
    }

    if (this.animation !== OrderAnimation.none && this.animationShouldChange(status)) {
      setTimeout(() => {
        this.animationUpdate(OrderAnimation.none);
      }, 200);
    }
  }

  public getTotalPrecision(market?: { totalPrecision: number }) {
    return getPrecision(this.displayTotal, market?.totalPrecision, true);
  }

  public getAmountPrecision(market?: { amountPrecision: number }) {
    return getPrecision(this.amount, market?.amountPrecision);
  }

  public getMarketPrecision(market?: { marketPrecision: number }) {
    return getPrecision(this.price, market?.marketPrecision);
  }

  private performBaseUpdate(sequence: number, time: string, timeNano: number | string) {
    const cTime = getTimestampNumber(time);

    this.timeNano = Number(timeNano);
    this.timeLastUpdated = new Date(cTime);
    this.timestampLastUpdated = new Date(cTime).toISOString();
    this.sequence = sequence;
  }

  private updateFilledAmount = (filledAmount: string) => {
    const newFA = new BigNumber(filledAmount);

    if (newFA.gt(this.filledAmount)) {
      this.filledAmount = newFA;
    }
  };

  public fastTimeNanoCheck = (timeNano: number | string) => Number(timeNano) > this.timeNano;

  public addTradeSettledUpdate(trade: TradeModel, sequence: number, time: string, timeNano: number | string, filledAmount: string) {
    if (trade.feeCurrency === CONSTANTS.BEST_CODE) {
      this.bestFeeAmount = this.bestFeeAmount.plus(trade.feeAmount);
    } else {
      this.feeAmount = this.feeAmount.plus(trade.feeAmount);
    }

    const total = Number.isNaN(+this.total) ? 0 : this.total;

    this.total = new BigNumber(trade.amount).times(trade.price).plus(total);
    this.trades.push(trade);

    if (this.fastTimeNanoCheck(timeNano)) {
      this.performBaseUpdate(sequence, time, timeNano);
      this.updateFilledAmount(filledAmount);

      if (this.isFullyFilled) {
        const potentialStatus = OrderStatus.FILLED_FULLY;

        if (this.status !== potentialStatus) {
          this.status = potentialStatus;
        }
      } else {
        const potentialStatus = OrderStatus.FILLED;

        if (!finalStatuses[potentialStatus].includes(this.status)) {
          this.status = potentialStatus;
        }
      }
    } else {
      logger.info('Ignored Trade settled because of sequence', 'orderId:', this.id, timeNano, '<=', this.timeNano);
    }

    return this.setMyOrdersType();
  }

  public addCancelledUpdate(sequence: number, time: string, timeNano: number | string, filledAmount: string) {
    if (this.fastTimeNanoCheck(timeNano)) {
      this.performBaseUpdate(sequence, time, timeNano);
      this.updateFilledAmount(filledAmount);
      this.status = this.hasFills ? OrderStatus.FILLED_CLOSED : OrderStatus.CLOSED;
    } else {
      logger.info('Cancel ignored because of sequence', 'orderId:', this.id, timeNano, '<=', this.timeNano);
    }

    return this.setMyOrdersType();
  }

  // this function ignores the sequencing - so if the order gets filled before it can be canceled for real the next update from WSS will overwrite the status again
  public addOptimisticCancelledUpdate() {
    this.status = OrderStatus.CLOSING;
    return this.setMyOrdersType();
  }

  public addTriggeredUpdate(sequence: number, time: string, timeNano: number | string) {
    if (this.fastTimeNanoCheck(timeNano)) {
      this.performBaseUpdate(sequence, time, timeNano);
      const potentialStatus = OrderStatus.STOP_TRIGGERED;

      if (!finalStatuses[potentialStatus].includes(this.status)) {
        this.status = potentialStatus;
      }

      this.timeTriggered = new Date(getTimestampNumber(time));
    } else {
      logger.info('triggered ignored because of sequence', 'orderId:', this.id, timeNano, '<=', this.timeNano);
    }

    return this.setMyOrdersType();
  }

  public addRejectedUpdate(sequence: number, _reason: string, time: string, timeNano: number | string, filledAmount: string) {
    if (this.fastTimeNanoCheck(timeNano)) {
      this.performBaseUpdate(sequence, time, timeNano);
      this.updateFilledAmount(filledAmount);
      this.status = this.hasFills ? OrderStatus.FILLED_REJECTED : OrderStatus.REJECTED;
    } else {
      logger.info('rejected ignored because of sequence', 'orderId:', this.id, timeNano, '<=', this.timeNano);
    }

    return this.setMyOrdersType();
  }

  public addProcessingFailedUpdate(sequence: number, time: string, timeNano: number | string) {
    if (this.fastTimeNanoCheck(timeNano)) {
      this.performBaseUpdate(sequence, time, timeNano);
      this.status = OrderStatus.FAILED;
    } else {
      logger.info('failed ignored because of sequence', 'orderId:', this.id, timeNano, '<=', this.timeNano);
    }

    return this.setMyOrdersType();
  }

  public addFullyFilledUpdate(sequence: number, time: string, timeNano: number | string, filledAmount: string) {
    if (this.fastTimeNanoCheck(timeNano)) {
      if (this.status === OrderStatus.FILLED_FULLY) {
        return;
      }

      this.performBaseUpdate(sequence, time, timeNano);
      this.filledAmount = new BigNumber(filledAmount);
      this.status = OrderStatus.FILLED_FULLY;
    } else {
      logger.info('fully filled ignored because of sequence', 'orderId:', this.id, timeNano, '<=', this.timeNano);
    }
  }

  public hasFallbackReason(): boolean {
    return this.trades.some((trade) => trade.fallbackReason.tooltip);
  }
}
