import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';

import { type ChartData } from '@exchange/libs/charting/service/src';

/**
 * https://firebase.google.com/docs/reference/js/firebase.firestore.FirestoreDataConverter
 */

export const getSettingsConverter = (): FirestoreDataConverter<Dictionary<unknown>> => ({
  toFirestore(doc: Dictionary<unknown>): DocumentData {
    return JSON.parse(JSON.stringify(doc));
  },

  fromFirestore(snapshot: QueryDocumentSnapshot<Dictionary<unknown>>, options: SnapshotOptions) {
    const data = snapshot.data(options);

    return data;
  },
});

export const getChartsConverter = (): FirestoreDataConverter<ChartData> => ({
  toFirestore(doc: ChartData): DocumentData {
    return doc;
  },

  fromFirestore(snapshot: QueryDocumentSnapshot<ChartData>, options: SnapshotOptions): ChartData {
    const data = snapshot.data(options);

    return data;
  },
});
