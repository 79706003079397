import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import isEmpty from 'lodash/isEmpty';
import { shallowRef } from 'vue';

import { secureStorageGet, secureStorageSet } from '@exchange/helpers/secure-storage-helpers';
import { modalService } from '@exchange/libs/modals/src';
import { NativeAppUpdateAvailable } from '@exchange/libs/native-app-update/ui/src';
import { launchdarkly } from '@exchange/libs/utils/launchdarkly/src';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger, nonProdConsoleInfo } from '@exchange/libs/utils/simple-logger/src';

type VersionNumber = string;
type CheckedTime = number;
type CheckedUpdateInfo = Record<VersionNumber, CheckedTime>;

type ShouldShowResult = {
  showModal: boolean;
  availableVersion: number | undefined;
  skippedForce?: boolean;
};

class NativeAppUpdateChecker {
  private readonly logPrefix = 'NativeAppUpdateChecker: ';

  private readonly countryToUseForIOSLookup = 'at'; // https://github.com/robingenz/capacitor-app-update/issues/12

  private readonly checkedUpdateInfoKey = 'checkedUpdateInfo';

  private readonly checkedUpdateDelay = 24 * 60 * 60 * 1000;

  private readonly checkRetryAttemptsNumber = 1;

  private openedModalKey: string | undefined = undefined;

  private checkedUpdateInfo = {
    get: () =>
      secureStorageGet<CheckedUpdateInfo>(
        this.checkedUpdateInfoKey,
        (info) => JSON.parse(info.value),
        () => ({}),
      ).then((value) => {
        nonProdConsoleInfo(`${this.logPrefix}stored "checked update info"`, JSON.stringify(value));

        return value;
      }) as Promise<CheckedUpdateInfo>,
    set: (value: CheckedUpdateInfo) => secureStorageSet(this.checkedUpdateInfoKey, JSON.stringify(value)),
  };

  private getUpdateAvailability = async () => {
    const result = await AppUpdate.getAppUpdateInfo({
      country: this.countryToUseForIOSLookup,
    });

    const getVersionNumber = (version?: string) => {
      if (!version) {
        return 0;
      }

      return Number(version.replace(/\./g, ''));
    };

    const updateAvailable = result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE;
    const { availableVersionReleaseDate } = result;
    const currentVersion = getVersionNumber(result.currentVersionName);
    const availableVersion = getVersionNumber(result.availableVersionName);

    nonProdConsoleInfo(
      this.logPrefix,
      'currentVersion',
      currentVersion,
      'availableVersion',
      availableVersion,
      'updateAvailability',
      result.updateAvailability,
      'updateAvailable',
      updateAvailable,
      'availableVersionReleaseDate',
      availableVersionReleaseDate,
    );

    return {
      updateAvailable,
      currentVersion,
      availableVersion,
      availableVersionReleaseDate,
    };
  };

  public openAppStore = async () => {
    try {
      await AppUpdate.openAppStore({
        country: this.countryToUseForIOSLookup,
      });
    } catch (error) {
      logger.error(`${this.logPrefix}Failed to open app store`, error);
    }
  };

  private shouldShowUpdateModal = async (forceUpdate: boolean, forceUpdateDelay: number, attempt = 0): Promise<ShouldShowResult> => {
    try {
      const [savedData, updateAvailability] = await Promise.all([this.checkedUpdateInfo.get(), this.getUpdateAvailability()]);

      const { updateAvailable, availableVersion, availableVersionReleaseDate } = updateAvailability;

      if (!updateAvailable) {
        return {
          showModal: false,
          availableVersion,
        };
      }

      const androidOkOrHopefullyIOSUpdatedItsCache = new Date().getTime() - new Date(availableVersionReleaseDate || 0).getTime() > forceUpdateDelay * 3600_000;

      if (forceUpdate && androidOkOrHopefullyIOSUpdatedItsCache) {
        return {
          showModal: true,
          availableVersion,
        };
      }

      const availableVersionCheckedAt = savedData[availableVersion];

      if (
        isEmpty(savedData) || // nothing was postponed
        availableVersionCheckedAt === undefined // availableVersion was not postponed
      ) {
        return {
          showModal: true,
          availableVersion,
          skippedForce: forceUpdate,
        };
      }

      const checkedAtDiff = new Date().getTime() - availableVersionCheckedAt;
      const showModal = checkedAtDiff > this.checkedUpdateDelay;

      nonProdConsoleInfo(this.logPrefix, 'update postponed at', availableVersionCheckedAt, 'update postponed at and now diff', checkedAtDiff, 'should show modal', showModal);

      return {
        showModal,
        availableVersion,
        skippedForce: forceUpdate,
      };
    } catch (error) {
      logger.error(`${this.logPrefix}Checking app update attempt ${attempt} failed with:`, error);
      await retryService.waitForNextRetryTick();

      if (attempt < this.checkRetryAttemptsNumber) {
        return this.shouldShowUpdateModal(forceUpdate, forceUpdateDelay, attempt + 1);
      }

      return {
        showModal: false,
        availableVersion: undefined,
        skippedForce: forceUpdate,
      };
    }
  };

  public checkAppUpdate = async () => {
    const [forceUpdate, forceUpdateDelay] = await Promise.all([launchdarkly.getWhenReady('force-app-update'), launchdarkly.getWhenReady('force-app-update-delay')]);
    const { availableVersion, showModal, skippedForce } = await this.shouldShowUpdateModal(forceUpdate, forceUpdateDelay);

    if (!showModal || this.openedModalKey || availableVersion === undefined) {
      return;
    }

    const canCloseModal = !forceUpdate || (forceUpdate && skippedForce);

    const setCheckData = () => {
      if (skippedForce) {
        return Promise.resolve();
      }

      return this.checkedUpdateInfo.set({
        [availableVersion]: new Date().getTime(),
      });
    };
    const closeModal = () => {
      if (forceUpdate && !skippedForce) {
        return;
      }

      modalService.hide(this.openedModalKey);
      this.openedModalKey = undefined;
    };

    const cancel = async () => {
      try {
        await setCheckData();
      } finally {
        closeModal();
      }
    };
    const confirm = async () => {
      try {
        await Promise.all([this.openAppStore(), setCheckData()]);
      } finally {
        closeModal();
      }
    };

    this.openedModalKey = modalService.show(
      shallowRef(NativeAppUpdateAvailable),
      {
        confirm,
        cancel,
        canClose: canCloseModal,
      },
      {
        'modals-container-on-outside-click': () => {
          /** modal is being hidden by ModalsContainer.vue */
          cancel();
        },
      },
      { canClose: canCloseModal },
    );
  };
}

export default new NativeAppUpdateChecker();
