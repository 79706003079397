import { type APIKeyPayload, type BEApiKey } from '@exchange/libs/api-keys/service/src';
import rest from '@exchange/libs/utils/rest/src';

const URL = '/keys';

export default function ApiKeysResource({ base, accountUrl }) {
  const keysResourceUrl = `${accountUrl}${URL}`;

  return {
    get(): Promise<{ account_id: string; keys: Array<BEApiKey> }> {
      return rest.request({
        url: keysResourceUrl,
        base,
      });
    },

    create(payload: APIKeyPayload) {
      return rest.request({
        method: 'post',
        payload,
        url: keysResourceUrl,
        base,
      });
    },

    delete(id: string) {
      return rest.request({
        method: 'delete',
        url: `${keysResourceUrl}/${id}`,
        base,
      });
    },

    // eslint-disable-next-line camelcase
    confirm({ key, payload }: { key: string; payload: { confirmation_code: string } }) {
      return rest.request({
        method: 'post',
        payload,
        url: `${keysResourceUrl}/${key}/confirm`,
        base,
      });
    },
  };
}
