import { MarketsChannelTemplate } from '@exchange/libs/utils/wss/src';
import { wsSportChannelNames, type WSChannelNames } from '@exchange/libs/utils/wss/src/lib/websocket-spot-model';

import type { OrderbookMessage } from './orderbook-model/interfaces';

export class OrderbookChannel extends MarketsChannelTemplate<OrderbookMessage> {
  protected get channelName(): WSChannelNames {
    return wsSportChannelNames.ORDER_BOOK;
  }
}

export default new OrderbookChannel();
