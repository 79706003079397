import rest from '@exchange/libs/utils/rest/src';

const URL = '/questionnaire';

export default function Resource({ base }) {
  const questionnaireUrl = `${base}${URL}`;

  return {
    get: (questionnaireKey: string): Promise<{ date: string; answers: Array<{ questionId: number; answer: string }> }> =>
      rest.request(
        {
          url: `${questionnaireUrl}/${questionnaireKey}`,
          base,
        },
        {
          useSubaccount: false,
        },
      ),
    set: (questionnaireKey: string, payload: { answers: { questionId: number; answer: string }[] }): Promise<void> =>
      rest.request(
        {
          url: `${questionnaireUrl}/${questionnaireKey}`,
          base,
          method: 'post',
          payload,
        },
        {
          useSubaccount: false,
        },
      ),
  };
}
