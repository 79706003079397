import { syncRefs } from '@vueuse/core';
import { computed, onBeforeUnmount, ref, Ref, watch, WatchStopHandle } from 'vue';

import { marketService } from '@exchange/libs/market/service/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import priceTicksService from './price-ticks.service';
import { PricetickModel } from './pricetick-model';

export default function usePriceTicks(marketId: Ref<string>) {
  const priceTicks = ref<Array<PricetickModel>>([]);
  const unsubscribe = ref<() => void>();
  const priceTicksSnapshotReceived = ref(false);
  const priceTicksAreLoading = computed(() => !priceTicksSnapshotReceived.value);

  let stopPT: WatchStopHandle;
  let stopPTSR: WatchStopHandle;

  const safeUnsubscribe = (mid?: string) => {
    try {
      unsubscribe.value?.();
    } catch (e) {
      logger.log('PriceTicks: unsubscribing failed', e, mid);
    }
  };

  const handleSubscribe = async (mid: string) => {
    stopPT?.();
    stopPTSR?.();

    safeUnsubscribe(mid);
    priceTicksSnapshotReceived.value = false;

    unsubscribe.value = priceTicksService.subscribe(mid, {
      success: (res: { priceTicks: Ref<Array<PricetickModel>>; snapshotReceived: Ref<boolean> }) => {
        stopPT = syncRefs(res.priceTicks, priceTicks);
        stopPTSR = syncRefs(res.snapshotReceived, priceTicksSnapshotReceived);
      },
      fail: (error) => {
        logger.error(`PriceTicks: failed to subscribe to ${mid}, retrying...`, error);
        handleSubscribe(mid);
      },
    });
  };

  watch(
    marketId,
    async (mid) => {
      if (!mid) {
        return;
      }

      await marketService.awaitMarkets();
      await handleSubscribe(mid);
    },
    { immediate: true },
  );

  onBeforeUnmount(() => {
    safeUnsubscribe();
  });

  return {
    priceTicks,
    priceTicksAreLoading,
  };
}
