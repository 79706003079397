import rest from '@exchange/libs/utils/rest/src';

const URL = '/price-change';

export default function Resource({ base }) {
  const pathURL = `${base}${URL}`;

  return {
    get: (markets: Array<string>): Promise<Record<string, Array<number>>> =>
      rest.unAuthorizedRequest({
        url: pathURL,
        method: 'post',
        base,
        payload: {
          markets,
        },
      }),
  };
}
