<script lang="ts" setup>
import { type PropType } from 'vue';

import { modalType, type ModalType, modalVariant, type ModalVariant, ModalFooterVariant, type ModalFooterVariantType } from './modal-type-validators';
import ModalContent from './ModalContent.vue';
import ModalFooter from './ModalFooter.vue';
import ModalHeader from './ModalHeader.vue';
import modalService from './modals.service';

const props = defineProps({
  /** false - to hide close icon and prevent closing the modal on background click  */
  canClose: { type: Boolean, default: true },
  title: { type: String },
  type: { type: String as PropType<ModalType>, default: modalType.base },
  variant: { type: String as PropType<ModalVariant>, default: modalVariant.light },
  footerVariant: { type: String as PropType<ModalFooterVariantType>, default: ModalFooterVariant.default },
  wide: { type: Boolean, default: false },
});
const emit = defineEmits<{
  (e: 'modal-close'): void;
}>();

const close = () => {
  if (props.canClose) {
    emit('modal-close');
    modalService.hide();
  }
};

const clear = () => {
  modalService.clear();
};
</script>

<template>
  <div
    ref="modal"
    :class="['modal', `modal--${type}`, `modal--${variant}`, { 'modal--wide': wide }]"
  >
    <modal-header
      :title="title"
      :type="type"
      :variant="variant"
      :can-close="canClose"
      @closed="close"
    >
      <template #default>
        <slot name="header" />
      </template>
    </modal-header>
    <modal-content
      :type="type"
      :variant="variant"
    >
      <slot
        v-if="$slots.content"
        name="content"
        :clear="clear"
      />
    </modal-content>
    <modal-footer
      v-if="$slots.buttons"
      :type="type"
      :variant="footerVariant"
    >
      <template #default="{ itemClass }">
        <slot
          name="buttons"
          :clear="clear"
          :item-class="itemClass"
        />
      </template>
    </modal-footer>
  </div>
</template>

<style lang="scss">
.modal {
  --modal-padding-x: 24px; /* modal variables, consumed by modal-header, modal-content and modal-footer */

  z-index: 1;
  width: var(--modal-width, 470px);
  min-height: 20px;
  border-radius: var(--app-border-radius);
  background-color: var(--modal-background-color);
  clip: rect(1px, 1px, 1px, 1px);

  /* show only one modal at the same time
  without using `display:none`, as autofocus
  doesn't apply for such elements */
  opacity: 0;
  will-change: auto;

  &:first-of-type {
    position: static;
    margin: auto;
    clip: auto;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }

  .xs & {
    display: flex;

    /* on animation it has pos:fixed
    so to keep these 12px margings as in zeplin
    we need these weird width to support
    different pos:fixed and static */
    width: calc(100% - 24px);
    flex-direction: column;
    margin: 20% auto;

    /* fix margin for the small screen height */
    @media (height <=568px) {
      margin: 12px auto;
    }
  }

  &[class*='v-enter-from'],
  &[class*='v-leave-from'] {
    position: fixed;
  }

  &[class*='v-enter-from'] {
    z-index: 2;
  }
}

.modal--wide {
  --modal-width: 560px;
}

.modal--light {
  --modal-background-color: var(--elevation-light-0);
}

.modal--dark {
  --modal-background-color: rgb(var(--v-theme-elevation-1));
}

/* Modal Types
// -------------------------------------------------- */

.modal--xs-fullscreen {
  .xs & {
    width: 100%;
    min-height: calc(var(--vh, 1vh) * 100);
    margin: 0;
  }

  .xs.is-native-app & {
    min-height: calc(100vh - var(--safe-area-top));
  }
}
</style>
