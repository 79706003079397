<script lang="ts" setup>
import { type PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import { ModalVariant } from '@exchange/libs/modals/src';
import InfoModal from '@exchange/libs/modals/src/lib/common/InfoModal.vue';

const props = defineProps({
  variant: { type: String as PropType<ModalVariant>, required: true },
});

const { t } = useI18n({ useScope: 'global' });

const emit = defineEmits<{
  (e: 'action-clicked'): void;
  (e: 'view-transactions'): void;
}>();
</script>

<template>
  <info-modal
    name="RestrictedJurisdictionModal"
    :action="t('modules.transactions.withdraw.restrictedJurisdictionModal.action')"
    :title="t('modules.transactions.withdraw.restrictedJurisdictionModal.title')"
    :message="t('modules.transactions.withdraw.restrictedJurisdictionModal.withdrawMessage')"
    :variant="variant"
    :icon-name="'close-circle-bulk'"
    :is-error="true"
    @action-clicked="emit('action-clicked')"
  />
</template>
