export const getFundingError = (error: unknown) => {
  let er = error;

  if (er && typeof er === 'object' && 'data' in er) {
    const { data } = er;

    if (data && typeof data === 'object' && 'error' in data) {
      er = data.error;
    } else {
      er = data;
    }
  }

  return er;
};

export default getFundingError;
