import {
  type IncomingFundingWithdrawalConfirm,
  type IncomingFundingWithdrawalInitiate,
  type OutgoingFundingWithdrawalCancel,
  type OutgoingFundingWithdrawalInitiate,
  type OutgoingWithdrawalConfirm,
} from '@exchange/libs/transactions/funds/service/src/lib/models/funding-withdrawal';
import rest from '@exchange/libs/utils/rest/src';

const withdrawalURL = 'account-holder/withdrawal';

export default function WithdrawResource({ base, fundingURL }) {
  const url = `${fundingURL}/${withdrawalURL}`;

  return {
    initiate({
      amount,
      auth_code: authCode,
      currency_code: currencyCode,
      payout_account_id: paId,
      type,
    }: OutgoingFundingWithdrawalInitiate): Promise<IncomingFundingWithdrawalInitiate> {
      return rest.request({
        method: 'post',
        payload: {
          amount,
          auth_code: authCode,
          currency_code: currencyCode,
          payout_account_id: paId,
          type,
        },
        url,
        base,
      });
    },

    confirm({ transactionId, secret }: OutgoingWithdrawalConfirm): Promise<IncomingFundingWithdrawalConfirm> {
      return rest.request({
        method: 'put',
        payload: {
          code: secret,
        },
        url: `${url}/${transactionId}/confirm`,
        base,
      });
    },

    cancel({ transactionId }: OutgoingFundingWithdrawalCancel) {
      return rest.request({
        method: 'delete',
        url: `${url}/${transactionId}`,
        base,
      });
    },
  };
}
