import { wsSportChannelNames, WSIncomingEventTypes, type WSIncomingBasicMessagePayload } from '@exchange/libs/utils/wss/src/lib/websocket-spot-model';

export type OrderbookSpread = {
  basicPoints: number;
  quoteCurrency: number;
};

export enum AggregationOperation {
  RAISE = 'raise',
  REDUCE = 'reduce',
  RESET = 'reset',
}

export enum OrderbookSide {
  ASK = 'ASK',
  BID = 'BID',
}

export interface PriceLevel {
  side: OrderbookSide;
  price: number;
  amount: number;
  total: number;
}

/* eslint-disable camelcase */
export interface OrderbookMessage extends WSIncomingBasicMessagePayload {
  channel_name: typeof wsSportChannelNames.ORDER_BOOK;
  instrument_code: string;
}

export interface OrderbookRest {
  asks: Array<[string, string]>;
  bids: Array<[string, string]>;
  instrument_code: string;
  time: string;
}
/* eslint-enable camelcase */
export interface OrderbookSnapshot extends OrderbookRest, OrderbookMessage {
  type: WSIncomingEventTypes.ORDER_BOOK_SNAPSHOT;
}

export enum OrderbookUpdateOperations {
  SELL = 'SELL',
  BUY = 'BUY',
}
export type OrderbookUpdateChange = [OrderbookUpdateOperations, string, string];

export interface OrderbookUpdate extends OrderbookMessage {
  changes: Array<OrderbookUpdateChange>;
  type: WSIncomingEventTypes.ORDER_BOOK_UPDATE;
}

export const ORDER_NUMBERS = 50;

export enum PriceLevelTransition {
  entering = 'entering',
  updating = 'updating',
  leaving = 'leaving',
}

export interface TransitionAblePriceLevel extends PriceLevel {
  transition?: PriceLevelTransition;
  transitionStart?: number;
}

export interface PriceLevelsWithMyOrders extends TransitionAblePriceLevel {
  side: OrderbookSide;
  priceValue: string;
  amountValue: string;
  totalValue: string;
  triggerBefore: boolean;
  triggerAt: boolean;
  orderAt: boolean;
}
