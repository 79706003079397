import rest from '@exchange/libs/utils/rest/src';

const URL = '/fees';

export default function FeesResource({ base, accountUrl }) {
  return {
    get() {
      return rest.request({
        url: `${accountUrl}${URL}`,
        base,
      });
    },
  };
}
