import BigNumber from '@exchange/helpers/bignumber';
import { map } from '@exchange/helpers/lodash-fp-no-cap';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import { OrderbookSide, AggregationOperation, OrderbookUpdateOperations, ORDER_NUMBERS } from './interfaces';
import type { OrderbookSnapshot, OrderbookUpdateChange } from './interfaces';
import { Side } from './side';
import type { UpdateListener } from './update-listener';
import { findIndex, calculateAggregation } from './util';

export class OrderbookProcessor {
  public asks = new Side(OrderbookSide.ASK);

  public bids = new Side(OrderbookSide.BID);

  public get currentAsks() {
    if (this.hasAggregation) {
      return this.currentAggregatedPriceLevel.asks.priceLevels.slice(0, ORDER_NUMBERS);
    }

    return this.asks.priceLevels.slice(0, ORDER_NUMBERS);
  }

  public get currentBids() {
    if (this.hasAggregation) {
      return this.currentAggregatedPriceLevel.bids.priceLevels.slice(0, ORDER_NUMBERS);
    }

    return this.bids.priceLevels.slice(0, ORDER_NUMBERS);
  }

  public get spread() {
    if (this.asks.priceLevels[0] && this.bids.priceLevels[0]) {
      const ba = this.asks.priceLevels[0].price;
      const bb = this.bids.priceLevels[0].price;
      const midpoint = (ba + bb) / 2;
      const currencySpread = ba - bb;

      return {
        basicPoints: 10_000 * (currencySpread / midpoint),
        quoteCurrency: currencySpread,
      };
    }

    return {
      basicPoints: 0,
      quoteCurrency: 0,
    };
  }

  public currentAggregationLevel = NaN;

  currentAggregatedPriceLevel = {
    asks: new Side(OrderbookSide.ASK),
    bids: new Side(OrderbookSide.BID),
  };

  public get hasAggregation(): boolean {
    return Number.isNaN(this.currentAggregationLevel) ? false : this.currentAggregationLevel !== -this.marketPrecision;
  }

  public possibleAggregationsLevels: { [key: string]: string } = {};

  public marketPrecision!: number;

  init(marketPrecision) {
    this.marketPrecision = marketPrecision;
    this.currentAggregationLevel = -marketPrecision;
    this.possibleAggregationsLevels = [0, 1, 2, 3, 4].reduce((aggregationMap, value) => {
      const offset = marketPrecision - value;

      return {
        ...aggregationMap,
        [-offset]: new BigNumber(10).pow(-offset).toString(),
      };
    }, {});

    return {
      currentAggregationLevel: this.currentAggregationLevel,
      possibleAggregationsLevels: this.possibleAggregationsLevels,
    };
  }

  public aggregate(currentAggregationLevel: number): void {
    this.currentAggregatedPriceLevel.asks = new Side(OrderbookSide.ASK);
    this.currentAggregatedPriceLevel.bids = new Side(OrderbookSide.BID);

    map((_val, key: 'bids' | 'asks') => {
      this[key].priceLevels.forEach(({ side, price, amount }) => {
        this.currentAggregatedPriceLevel[key].addUpdateAgr(calculateAggregation({ price, side, aggregationLevel: currentAggregationLevel }), price, amount);
      });
    }, this.currentAggregatedPriceLevel);
  }

  addUpdateListener(side: OrderbookSide, updateListener: UpdateListener) {
    const foo = this.hasAggregation ? this.currentAggregatedPriceLevel : this;

    if (side === OrderbookSide.ASK) {
      return foo.asks.addUpdateListener(updateListener);
    }

    return foo.bids.addUpdateListener(updateListener);
  }

  clear() {
    const askListeners = this.asks.updateListeners;
    const bidListeners = this.bids.updateListeners;

    this.asks = new Side(OrderbookSide.ASK);
    this.bids = new Side(OrderbookSide.BID);

    this.asks.updateListeners = askListeners;
    this.bids.updateListeners = bidListeners;

    const agAskListeners = this.currentAggregatedPriceLevel.asks.updateListeners;
    const agBidListeners = this.currentAggregatedPriceLevel.bids.updateListeners;

    this.currentAggregatedPriceLevel = {
      asks: new Side(OrderbookSide.ASK),
      bids: new Side(OrderbookSide.BID),
    };

    this.currentAggregatedPriceLevel.asks.updateListeners = agAskListeners;
    this.currentAggregatedPriceLevel.bids.updateListeners = agBidListeners;

    this.currentAggregationLevel = NaN;
    this.marketPrecision = NaN;

    this.possibleAggregationsLevels = {};
    this.marketPrecision = NaN;

    return {
      asks: this.currentAsks,
      bids: this.currentBids,
      spread: this.spread,
    };
  }

  setSnapshot(snapshot: OrderbookSnapshot) {
    this.asks.addSnapshot(snapshot.asks);
    this.bids.addSnapshot(snapshot.bids);

    if (this.hasAggregation) {
      this.aggregate(this.currentAggregationLevel);
    }

    return {
      asks: this.currentAsks,
      bids: this.currentBids,
      spread: this.spread,
    };
  }

  addUpdate(changes: Array<OrderbookUpdateChange>) {
    changes.forEach(([side, price, amount]) => {
      const orderbookSide = side.toUpperCase() === OrderbookUpdateOperations.SELL ? 'asks' : 'bids';
      const priceNumber = parseFloat(price);

      const { index, exists } = findIndex(priceNumber, this[orderbookSide].priceLevels, side === OrderbookUpdateOperations.SELL ? OrderbookSide.ASK : OrderbookSide.BID);

      const newAmount = exists ? new BigNumber(amount).minus(this[orderbookSide].priceLevels[index]?.amount || 0).toNumber() : +amount;

      this[orderbookSide].addUpdate(+price, newAmount, { index, exists });

      if (this.hasAggregation) {
        const aggregatedPrice = calculateAggregation({ price: priceNumber, side, aggregationLevel: this.currentAggregationLevel });

        this.currentAggregatedPriceLevel[orderbookSide].addUpdate(aggregatedPrice, newAmount);
      }
    });

    const bestAsk = this.asks.priceLevels[0]?.price || 0;
    const bestBid = this.bids.priceLevels[0]?.price || 0;
    const spread = bestAsk - bestBid;

    if (spread < 0 && bestAsk !== 0 && bestBid !== 0) {
      logger.error(
        'NEGATIVE SPREAD DETECTED',
        JSON.stringify({
          bestAsk,
          bestBid,
          spread,
        }),
      );
    }

    return {
      asks: this.currentAsks,
      bids: this.currentBids,
      spread: this.spread,
    };
  }

  doAggregation(operation: AggregationOperation, marketPrecision?: number) {
    switch (operation) {
      case AggregationOperation.RAISE:
        this.currentAggregationLevel += 1;
        break;
      case AggregationOperation.REDUCE:
        this.currentAggregationLevel -= 1;
        break;
      case AggregationOperation.RESET:
      default:
        this.init(marketPrecision ?? this.marketPrecision);
    }

    this.aggregate(this.currentAggregationLevel);

    return {
      currentAggregationLevel: this.currentAggregationLevel,
      asks: this.currentAsks,
      bids: this.currentBids,
      spread: this.spread,
    };
  }

  canDoAggregation(operation: AggregationOperation) {
    const updateTo = operation === AggregationOperation.RAISE ? 1 : -1;

    return !!this.possibleAggregationsLevels[this.currentAggregationLevel + updateTo];
  }
}
