import { type BEMarketTicker } from '@exchange/libs/market/service/src';
import rest from '@exchange/libs/utils/rest/src';

const URL = '/market-ticker';

export default function MarketTickerResource({ base }) {
  return {
    get(): Promise<Array<BEMarketTicker>> {
      return rest.unAuthorizedRequest({
        url: URL,
        base,
      });
    },
    getInstrument(instrumentCode: string): Promise<BEMarketTicker> {
      return rest.unAuthorizedRequest({
        url: `${URL}/${instrumentCode.replace(/"/g, '')}`,
        base,
      });
    },
  };
}
