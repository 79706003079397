<script lang="ts" setup>
import { type PropType } from 'vue';

import useMQBreakpoints from '@exchange/libs/composables/shared/src/lib/useMQBreakpoints';

import { modalType, ModalFooterVariant, type ModalFooterVariantType, type ModalType } from './modal-type-validators';

defineProps({
  type: { type: String as PropType<ModalType>, default: modalType.base },
  variant: { type: String as PropType<ModalFooterVariantType>, default: ModalFooterVariant.default },
});

const { isSMAndDown } = useMQBreakpoints();
</script>

<template>
  <div :class="['modal-footer', `modal-footer--${type}`, `modal-footer--${variant}`]">
    <div
      v-if="isSMAndDown"
      class="modal-footer__inner modal-footer__inner--changing-order"
    >
      <slot item-class="modal-footer__item" />
    </div>
    <div
      v-else
      class="modal-footer__inner"
    >
      <slot item-class="modal-footer__item" />
    </div>
  </div>
</template>

<style lang="scss">
.modal-footer {
  --m-footer-padding-top: var(--modal-footer-padding-top, 20px);
  --m-footer-padding-bottom: var(--modal-padding-bottom, 24px);
  --m-footer-padding-x: var(--modal-padding-x, 16px);

  padding: var(--m-footer-padding-top) var(--m-footer-padding-x) var(--m-footer-padding-bottom);
  border-bottom-left-radius: var(--app-border-radius);
  border-bottom-right-radius: var(--app-border-radius);

  &__inner {
    display: flex;
    min-height: min-content;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    &--changing-order {
      flex-direction: column-reverse;
      gap: 8px;

      button[type='button'] {
        width: 100%;
      }
    }
  }

  &__inner button[type='button'] {
    min-width: 100px;
  }
}

.modal-footer--centered {
  display: flex;

  .modal-footer {
    &__inner {
      flex: 1;
      justify-content: center;
      gap: 16px;
    }

    &__inner:has(button[type='button']:nth-last-child(n + 2)) button[type='button'] {
      flex: 1;
    }
  }
}
</style>
