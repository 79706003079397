import { ExportItem, BEExport, BEExportsList, BERequestTransactionsExport, BERequestTradesExport } from '@exchange/libs/exports/service/src';
import rest from '@exchange/libs/utils/rest/src';

import ExportResources from './export';

const URL = 'exports';

export default function ExportsResources({ base }) {
  return {
    async getAll() {
      const res: BEExportsList = await rest.request(
        {
          url: URL,
          base,
        },
        {
          useSubaccount: false,
        },
      );

      return res.exports.map((i) => new ExportItem(i));
    },
    async transactions(payload: BERequestTransactionsExport) {
      const res: BEExport = await rest.request(
        {
          url: `${URL}/transactions`,
          base,
          method: 'post',
          payload,
        },
        {
          useSubaccount: false,
        },
      );

      return res;
    },
    async trades(payload: BERequestTradesExport) {
      const res: BEExport = await rest.request(
        {
          url: `${URL}/trades`,
          base,
          method: 'post',
          payload,
        },
        {
          useSubaccount: false,
        },
      );

      return res;
    },
    Export: (id: string) => ExportResources({ base, exportURL: `${URL}/${id}` }),
  };
}
