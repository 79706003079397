export enum OrderType {
  LIMIT = 'LIMIT',
  MARKET = 'MARKET',
  STOP = 'STOP',
}

export enum OrderSide {
  BUY = 'BUY',
  SELL = 'SELL',
}

export enum OrderSnapshotType {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum MyOrdersListType {
  open = 'open',
  filled = 'filled',
}

export enum MyOrdersTabs {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  HIDDEN = 'HIDDEN',
}

export enum OrderStatus {
  OPEN = 'OPEN',
  STOP_TRIGGERED = 'STOP_TRIGGERED',
  FILLED = 'FILLED',
  FILLED_CLOSED = 'FILLED_CLOSED',
  FILLED_FULLY = 'FILLED_FULLY',
  CLOSING = 'CLOSING',
  CLOSED = 'CLOSED',
  REJECTED = 'REJECTED',
  FILLED_REJECTED = 'FILLED_REJECTED',
  FAILED = 'FAILED',
}

export const OrderStatusFastToLegacy = {
  OPEN: 'OPEN',
  BOOKED: 'OPEN',
  STOP_TRIGGERED: 'STOP_TRIGGERED',
  FILLED: 'FILLED',
  FILL: 'FILLED',
  FILLED_CLOSED: 'FILLED_CLOSED',
  FILLED_FULLY: 'FILLED_FULLY',
  CLOSING: 'CLOSING',
  CLOSED: 'CLOSED',
  CANCELLED: 'CLOSED',
  REJECTED: 'REJECTED',
  SELF_TRADE: 'REJECTED',
  INSUFFICIENT_LIQUIDITY: 'REJECTED',
  INSUFFICIENT_FUNDS: 'REJECTED',
  FILLED_REJECTED: 'FILLED_REJECTED',
  FAILED: 'FAILED',
};

export const finalStatuses = {
  common: [OrderStatus.CLOSED, OrderStatus.FAILED, OrderStatus.FILLED_CLOSED, OrderStatus.FILLED_FULLY, OrderStatus.FILLED_REJECTED, OrderStatus.REJECTED],
  get [OrderStatus.FILLED]() {
    return this.common;
  },
  get [OrderStatus.STOP_TRIGGERED]() {
    return this.common;
  },
};

export enum OrderAnimation {
  fail = 'fail',
  success = 'success',
  none = '',
}

export enum TimeInForceType {
  FILL_OR_KILL = 'FILL_OR_KILL',
  GOOD_TILL_CANCELLED = 'GOOD_TILL_CANCELLED',
  GOOD_TILL_TIME = 'GOOD_TILL_TIME',
  IMMEDIATE_OR_CANCELLED = 'IMMEDIATE_OR_CANCELLED',
}

export enum ExecutionType {
  POST_ONLY = 'POST_ONLY',
  ALLOW_TAKER = 'ALLOW_TAKER',
}

/* eslint-disable camelcase */
export interface BEOrderModel {
  account_holder: string;
  account_id: string;
  amount: string;
  expire_after?: Date;
  filled_amount: string;
  instrument_code: string;
  is_post_only?: boolean;
  order_id: string;
  price?: string;
  sequence: number;
  side: OrderSide;
  time_in_force?: TimeInForceType;
  time_last_updated?: string; // only for filled orders
  time_triggered?: string;
  time: string;
  trigger_price?: string;
  type: OrderType;
}

export interface BEOrderSnapshotModel extends BEOrderModel {
  status: OrderStatus;
  average_price?: string; // only if it has trades
  update_modification_sequence?: number; // only if it has trades
  total_fee?: number; // calculated with average price
  fee_currency?: string;
  time_nano?: string | number;
}
/* eslint-enable camelcase */

export interface InMyOrderType {
  before: Array<MyOrdersListType> | undefined;
  now: Array<MyOrdersListType> | undefined;
}
