import AgreementResource from './agreement-resource';
import FuturesAppropriatenessResource from './futures-appropriateness-resource';
import VerificationInfoResource from './verification-information-resource';

const url = '/customer';

export default function CustomerResources({ base }) {
  return {
    Agreement: AgreementResource({ base: `${base}${url}` }),
    FuturesAppropriateness: FuturesAppropriatenessResource({ base: `${base}${url}` }),
    VerificationInfo: VerificationInfoResource({ base: `${base}${url}` }),
  };
}
