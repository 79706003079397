import { Candlestick } from '@exchange/libs/charting/service/src';
import type { BECandlestick } from '@exchange/libs/charting/service/src';
import rest from '@exchange/libs/utils/rest/src';

const URL = '/candlesticks';

export default function CandlesticksResource({ base }) {
  return {
    async get({ from, to, countBack, unit, period }: { from: number; to: number; countBack: number; unit: string; period: number }, marketId: string) {
      const candlesSearchParams = new URLSearchParams();

      candlesSearchParams.append('unit', unit);
      candlesSearchParams.append('period', period.toString());
      candlesSearchParams.append('from', new Date(from * 1000).toISOString());
      candlesSearchParams.append('to', new Date(to * 1000).toISOString());
      candlesSearchParams.append('countBack', countBack.toString());

      const {
        candlesticks,
        next_bar: nextBar,
        status,
      } = await rest.unAuthorizedRequest({
        url: `${URL}/${marketId}?${candlesSearchParams.toString()}`,
        base,
      });

      return {
        candlesticks: candlesticks ? (candlesticks as Array<BECandlestick>).map((item) => new Candlestick(item)) : [],
        nextTime: nextBar ? new Date(nextBar).getTime() : undefined,
        status,
      };
    },
  };
}
