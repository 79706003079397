import rest from '@exchange/libs/utils/rest/src';

const URL = '/views';

type MarketStatInfo = {
  instrument: string;
  count: number;
  last_price: string;
  fist_price: string;
  price_points: Array<string>;
};

export default function ViewsResource({ base, statUrl }) {
  const viewUrl = `${statUrl}${URL}`;

  return {
    get: (): Promise<MarketStatInfo[]> =>
      rest.unAuthorizedRequest({
        url: viewUrl,
        base,
      }),
    set: (instrument: string, price: string): Promise<void> =>
      rest.unAuthorizedRequest({
        url: viewUrl,
        method: 'post',
        payload: {
          instrument,
          price,
        },
        base,
      }),
  };
}
