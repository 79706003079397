import AccountsResource from './accounts';
import SymbolsResource from './symbols';
import TradesResource from './trades';

const API_DOMAIN = process.env.VUE_APP_EOTC_API_DOMAIN;
const API_VERSION = 'v1';
const API_BASE = `${API_DOMAIN}/${API_VERSION}`;

export const oAuthRest = {
  Balances: AccountsResource({ base: API_BASE }),
  Symbols: SymbolsResource({ base: API_BASE }),
  Trades: TradesResource({ base: API_BASE }),
};

export default oAuthRest;
