<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import InfoModal from '@exchange/libs/modals/src/lib/common/InfoModal.vue';

type Action = {
  action: string;
  actionFn: () => void;
  actionTestId: string;
};

type Actions = [Action, Action] | [Action];

const { t } = useI18n({ useScope: 'global' });

const props = defineProps<{
  canNotifyMe: boolean;
}>();

const emit = defineEmits<{
  (e: 'contact-support'): void;
  (e: 'notify-me'): void;
}>();

const contactSupportAction: Action = {
  action: t('ui.forbiddenJurisdictionModal.secondaryAction'),
  actionFn: () => emit('contact-support'),
  actionTestId: 'contact-support',
};

const notifyMeAction: Action = {
  action: t('ui.forbiddenJurisdictionModal.primaryAction'),
  actionFn: () => emit('notify-me'),
  actionTestId: 'notify-me',
};

const actions = computed((): Actions => (props.canNotifyMe ? [notifyMeAction, contactSupportAction] : [contactSupportAction]));
</script>

<template>
  <info-modal
    content-test-id="forbidden-jurisdiction-modal-content"
    variant="dark"
    :no-icon="true"
    :title="t('ui.forbiddenJurisdictionModal.title')"
    :message="t('ui.forbiddenJurisdictionModal.message')"
    :action="actions[0].action"
    :action-test-id="actions[0].actionTestId"
    :action-secondary="actions[1]?.action"
    :action-secondary-test-id="actions[1]?.actionTestId"
    @action-clicked="actions[0].actionFn"
    @action-secondary-clicked="actions[1]?.actionFn"
  />
</template>
