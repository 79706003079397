import AccountHolderResources from './account-holder';
import DepositResource from './deposit';
import EOTCResource from './eotc';
import PayoutAccountsResource from './payout-accounts';
import TransactionsResource from './transactions';
import TransferMethodsResource from './transfer-methods';
import WithdrawResource from './withdrawal';

const URL = '/funding';

export default function FundingResource({ base }) {
  return {
    AccountHolder: { ...AccountHolderResources({ base, fundingURL: URL }) },
    Deposit: DepositResource({ base, fundingURL: URL }),
    EOTC: { ...EOTCResource({ base, fundingURL: URL }) },
    PayoutAccounts: PayoutAccountsResource({ base, fundingURL: URL }),
    Transactions: TransactionsResource({ base, fundingURL: URL }),
    TransferMethods: TransferMethodsResource({ base, fundingURL: URL }),
    Withdraw: WithdrawResource({ base, fundingURL: URL }),
  };
}
