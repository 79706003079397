import rest from '@exchange/libs/utils/rest/src';

const ADDRESS_URL = '/addresses';

export default function AddressesResource({ base, url }) {
  return {
    post(currencyCode: string) {
      return rest.request({
        method: 'post',
        url: `${url}${ADDRESS_URL}`,
        payload: { currency_code: currencyCode },
        base,
      });
    },
  };
}
