import rest from '@exchange/libs/utils/rest/src';

const URL = '/symbols';

export interface SymbolCurrency {
  code: string;
  precision: number;
}
export interface IncomingSymbol {
  base: SymbolCurrency;
  quote: SymbolCurrency;
  min_amt: string;
  min_price_inc: string;
  min_size_inc: string;
  min_size: string;
}

export default function Resource({ base }) {
  return {
    getAll(): Promise<Array<IncomingSymbol>> {
      return rest.unAuthorizedRequest({
        url: URL,
        base,
      });
    },
  };
}
