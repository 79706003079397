import { type User } from 'firebase/auth';
import {
  doc,
  DocumentSnapshot,
  FieldValue,
  Firestore,
  FirestoreDataConverter,
  FirestoreError,
  getDoc,
  onSnapshot,
  setDoc,
  Unsubscribe,
  updateDoc,
  WithFieldValue,
} from 'firebase/firestore';
import type { SnapshotMetadata } from 'firebase/firestore';

import { logger } from '@exchange/libs/utils/simple-logger/src';

export const getUserString = (currentUser: User) => {
  let userString;
  const user = currentUser.toJSON();

  try {
    userString = JSON.stringify(user);
  } catch (e) {
    // ignore
  }

  return userString || user;
};

export interface GetConverter<T> {
  ({ accountId, subaccountId }: { accountId: string; subaccountId: string }): FirestoreDataConverter<T>;
  (): FirestoreDataConverter<T>;
}

export interface CollectionAccessorResult<T> {
  get: (info?: { subaccountId?: string }) => Promise<T | undefined>;
  set: (docSnap: T, info?: { subaccountId?: string }) => Promise<void>;
  update: (path: string, value: unknown) => Promise<void>;
  createSubscription: (
    onSnapshotReceived: (v: T | undefined, metadata: SnapshotMetadata) => void | Promise<void>,
    onSubscriptionError?: (error: FirestoreError) => unknown,
    info?: { subaccountId: undefined | string },
  ) => Promise<void>;
  removeSubscription: () => void;
}

export const collectionAccessor =
  <T>(name: string, getConverter: GetConverter<T>) =>
  ({ db, logPrefix, getCurrentUserUID }: { db: Firestore; logPrefix: string; getCurrentUserUID: (name: string) => () => Promise<string> }): CollectionAccessorResult<T> => {
    let unsubscribe: Unsubscribe;

    const getUserUID = getCurrentUserUID(name);

    const getFinalConverter = (accountId: string, subaccountId?: string) => (subaccountId ? getConverter({ subaccountId, accountId }) : getConverter());

    return {
      async get({ subaccountId }: { subaccountId?: string } = {}): Promise<T | undefined> {
        try {
          const uid = await getUserUID();
          const converter = getFinalConverter(uid, subaccountId);

          const ref = doc(db, name, uid).withConverter(converter);

          const docSnap = await getDoc(ref);

          if (!docSnap.exists()) {
            return undefined;
          }

          return <T>docSnap.data();
        } catch (e) {
          logger.error(e);
          throw e;
        }
      },

      async createSubscription(
        onSnapshotReceived: (v: T | undefined, metadata: SnapshotMetadata) => void | Promise<void>,
        onSubscriptionError?: (error: FirestoreError) => unknown,
        { subaccountId }: { subaccountId?: string } = {},
      ) {
        const uid = await getUserUID();

        const onNext = async (snapshot: DocumentSnapshot<T>) => {
          const data = snapshot.data();

          logger.info(`${logPrefix}snapshot data`, data);

          if (data) {
            onSnapshotReceived(data, snapshot.metadata);
          }
        };

        const onErrorDefault = (error: FirestoreError) => {
          const subscription = `${name} subscription`;

          logger.error(`${logPrefix}${subscription} failed; userUID=${uid}; reloading current page`, error);
          setTimeout(() => window.location.reload(), 5_000);
        };

        const converter = getFinalConverter(uid, subaccountId);

        unsubscribe = onSnapshot(doc(db, name, uid).withConverter(converter), { includeMetadataChanges: false }, onNext, onSubscriptionError || onErrorDefault);
      },

      async set(docSnap: T, { subaccountId }: { subaccountId?: string } = {}): Promise<void> {
        try {
          const uid = await getUserUID();
          const converter = getFinalConverter(uid, subaccountId);

          const ref = doc(db, name, uid).withConverter(converter);

          return await setDoc(ref, docSnap as WithFieldValue<T>);
        } catch (e) {
          logger.error(e);
          throw e;
        }
      },

      async update(path: string, value: unknown): Promise<void> {
        try {
          const uid = await getUserUID();
          const ref = doc(db, name, uid);
          const data = <{ [key: string]: FieldValue }>{ [path]: value };

          await updateDoc(ref, data);
        } catch (e) {
          logger.error(e);
          throw e;
        }
      },

      removeSubscription() {
        unsubscribe?.();
      },
    };
  };

export default collectionAccessor;
