import FeesResource from './fees-resource';
import OrdersResource from './orders-resource';
import TradesResource from './trades-resource';

const ACCOUNT_URL = '/account';

export default function AccountResources({ base }) {
  return {
    Fees: FeesResource({ base, accountUrl: ACCOUNT_URL }),
    Orders: OrdersResource({ base, accountUrl: ACCOUNT_URL }),
    Trades: TradesResource({ base, accountUrl: ACCOUNT_URL }),
  };
}
