import rest from '@exchange/libs/utils/rest/src';

import BlockpitResource from './blockpit-resource';

const USER_URL = '/user';

function UserResources({ base, baseFast, userId }) {
  const userUrl = `${USER_URL}/${userId}`;

  return {
    async get() {
      const response = await rest.request(
        {
          url: userUrl,
          base: baseFast,
        },
        {
          useSubaccount: false,
        },
      );

      return response.data.attributes;
    },
    Blockpit: BlockpitResource({ base, userUrl }),
  };
}

export default UserResources;
