import { ShallowRef } from 'vue';

import { type ModalVariant } from './modal-type-validators';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RootInstance = any;

export interface ModalToShowOptions {
  isPrivate?: boolean;
  canClose?: boolean;
  variant?: ModalVariant;
  centered?: boolean;
}

export interface ModalToShow {
  isPrivate?: boolean;
  canClose?: boolean;
  key?: string;
  name?: string;
  props?: Dictionary<unknown>;
  listeners?: Dictionary<unknown>;
  options?: ModalToShowOptions;
  component: ShallowRef;
}

/** similar to https://github.com/euvl/vue-js-modal#readme */

class ModalsServices {
  private rootInstance: RootInstance | null = null;

  public registeredModals: Array<ModalToShow> = [];

  public initialize = (rt: RootInstance) => {
    this.rootInstance = rt;
  };

  // eslint-disable-next-line default-param-last
  public show = (modal, props: Dictionary<unknown> = {}, listeners: Dictionary<unknown> = {}, compOptions?: Dictionary<unknown>) => {
    const container = this.rootInstance;

    return container?.show(modal, props, listeners, compOptions);
  };

  // eslint-disable-next-line default-param-last
  public substitute = (modal, props: Dictionary<unknown> = {}, listeners: Dictionary<unknown> = {}, compOptions?: Dictionary<unknown>) => {
    const container = this.rootInstance;

    return container?.substitute(modal, props, listeners, compOptions);
  };

  public hide = (id?: string) => {
    const container = this.rootInstance;

    requestAnimationFrame(() => container?.hide(id));
  };

  public clear = () => {
    const container = this.rootInstance;

    container?.clear();
  };

  public registerModals = (modals: Array<{ component: ShallowRef; name: string; options: ModalToShowOptions }>) => {
    modals.forEach((modal) => {
      this.registeredModals.push(modal);
    });
  };
}

const modalService = new ModalsServices();

export default modalService;
