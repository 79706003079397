<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  type: { type: String as PropType<'exchange' | 'eotc'>, required: true },
});
const emit = defineEmits<{
  (e: 'close'): void;
}>();

const { t } = useI18n({ useScope: 'global' });

const message = computed(() => t(`modules.modals.unableToConnect.${props.type}.message`));
const actionText = computed(() => t('fundamentals.refresh'));

const action = () => window.location.reload();
</script>

<template>
  <div class="connection-ribbon">
    <div class="connection-ribbon__legend">
      <div class="connection-ribbon__legend-message">{{ message }}</div>
      <button
        class="connection-ribbon__legend-button"
        @click="action"
      >
        {{ actionText }}
      </button>
    </div>
    <button
      class="connection-ribbon__close"
      @click="emit('close')"
    >
      <ot-icon name="close" />
    </button>
  </div>
</template>

<style lang="scss">
.connection-ribbon {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  background-color: rgb(var(--v-theme-warning));
  color: var(--text-0);

  &__legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
    gap: 20px;
  }

  &__legend-button {
    color: var(--text-0);
    font-weight: inherit;
    text-decoration: underline;
  }

  &__close {
    position: absolute;
    right: 20px;
    min-width: 24px;
    flex: 0 1 auto;
    color: var(--text-0);
  }
}
</style>
