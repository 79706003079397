import { AggregationOperation, type OrderbookSpread, OrderbookSnapshot, OrderbookUpdateChange, OrderbookSide } from './interfaces';
import { OrderbookProcessor } from './processor.worker';
import SimpleOrderbook from './simple';
import type { UpdateListener } from './update-listener';

/**
 * Orderbook model
 */
export class Orderbook extends SimpleOrderbook {
  public get bestBid() {
    return this.bids[0];
  }

  public get bestAsk() {
    return this.asks[0];
  }

  public spread: OrderbookSpread = {
    basicPoints: 0,
    quoteCurrency: 0,
  };

  public currentAggregationLevel?: number;

  public possibleAggregationsLevels?: {
    [key: string]: string;
  };

  public orderbookProcessor = new OrderbookProcessor();

  public beforeSnapshotListeners: Array<() => void> = [];

  public afterSnapshotListeners: Array<() => void> = [];

  constructor(public override marketPrecision: number) {
    super(marketPrecision);

    const { possibleAggregationsLevels, currentAggregationLevel } = this.orderbookProcessor.init(marketPrecision);

    this.currentAggregationLevel = currentAggregationLevel;
    this.possibleAggregationsLevels = possibleAggregationsLevels;
  }

  addSnapshotListeners(beforeListener, afterListener) {
    this.beforeSnapshotListeners.push(beforeListener);
    this.afterSnapshotListeners.push(afterListener);

    return () => {
      this.beforeSnapshotListeners = this.beforeSnapshotListeners.filter((b) => b !== beforeListener);
      this.afterSnapshotListeners = this.afterSnapshotListeners.filter((a) => a !== afterListener);
    };
  }

  public getBestPrice(side: 'ask' | 'bid') {
    const bestLevel = side === 'ask' ? this.bestAsk : this.bestBid;

    return bestLevel ? bestLevel.price : 0;
  }

  public doAggregation(operation: AggregationOperation, marketPrecision?: number) {
    this.callBeforeSnapshotListeners();

    const { currentAggregationLevel, bids, asks, spread } = this.orderbookProcessor.doAggregation(operation, marketPrecision);

    this.bids = bids;
    this.asks = asks;
    this.spread = spread;
    this.currentAggregationLevel = currentAggregationLevel;

    this.callAfterSnapshotListeners();

    return currentAggregationLevel;
  }

  public canDoAggregation(operation: AggregationOperation) {
    if (!this.possibleAggregationsLevels || this.currentAggregationLevel === undefined) {
      return false;
    }

    const updateTo = operation === AggregationOperation.RAISE ? 1 : -1;

    return !!this.possibleAggregationsLevels[this.currentAggregationLevel + updateTo];
  }

  public clearOrderbook() {
    const { bids, asks, spread } = this.orderbookProcessor.clear();

    this.bids = bids;
    this.asks = asks;
    this.spread = spread;
  }

  public setSnapshot(snapshot: OrderbookSnapshot) {
    this.callBeforeSnapshotListeners();

    const { bids, asks, spread } = this.orderbookProcessor.setSnapshot(snapshot);

    this.bids = bids;
    this.asks = asks;
    this.spread = spread;

    this.callAfterSnapshotListeners();
  }

  public addUpdate(changes: Array<OrderbookUpdateChange>) {
    const { bids, asks, spread } = this.orderbookProcessor.addUpdate(changes);

    this.bids = bids;
    this.asks = asks;
    this.spread = spread;
  }

  public addUpdateListener(side: OrderbookSide, updateListener: UpdateListener) {
    return this.orderbookProcessor.addUpdateListener(side, updateListener);
  }

  public callBeforeSnapshotListeners() {
    this.beforeSnapshotListeners.forEach((l) => l());
  }

  public callAfterSnapshotListeners() {
    this.afterSnapshotListeners.forEach((l) => l());
  }
}
