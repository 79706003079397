<script lang="ts" setup>
import { computed, ref, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import { type VerificationFlag } from '@exchange/libs/utils/launchdarkly/src';

const props = defineProps({
  verification: { type: Object as PropType<VerificationFlag>, required: true },
});

const { t } = useI18n({ useScope: 'global' });

const closed = ref(false);

const verificationReady = computed(() => props.verification.ready);
const ribbonData = computed(() => ({
  link: props.verification.link,
  text: verificationReady.value ? t('modules.ribbons.verify.text') : t('modules.ribbons.verify.textNotReady'),
  icon: {
    text: t('modules.ribbons.verify.button.text'),
    name: 'verify',
  },
}));
</script>

<template>
  <div
    v-if="!closed"
    class="security-ribbon"
  >
    <div class="security-ribbon__legend">
      <div class="security-ribbon__legend-message">{{ ribbonData.text }}</div>
      <x-button
        v-if="verificationReady"
        class="legend__legend-button"
        size="xs"
        variant="primary"
        :text="ribbonData.icon.text"
        :href-custom-icon="{ name: ribbonData.icon.name }"
        :href="ribbonData.link"
      />
    </div>
    <button
      class="security-ribbon__close"
      @click="closed = !closed"
    >
      <ot-icon name="close" />
    </button>
  </div>
</template>

<style lang="scss">
.security-ribbon {
  position: relative;
  display: flex;
  height: 40px;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgb(var(--v-theme-primary));
  color: var(--text-0);
  letter-spacing: 0.3px;

  .sm &,
  .xs & {
    height: auto;
    flex-direction: column;
    justify-content: center;
    white-space: normal;
  }

  &__legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
    white-space: nowrap;

    .sm &,
    .xs & {
      flex-direction: column;
      justify-content: center;
      white-space: normal;
    }
  }

  &__legend-message {
    margin-right: 20px;

    .sm &,
    .xs & {
      margin: 10px 50px 0 20px;
      text-align: center;
    }
  }

  &__legend-button {
    border: 1px solid rgb(var(--v-theme-primary-disabled));

    /* we have no suitable color variant for buttons that placed
  on primary color background, so this is a customization approved by Patrik */
    background-color: rgb(var(--v-theme-primary-disabled));

    .sm &,
    .xs & {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }

  &__close {
    position: absolute;
    right: 20px;
    min-width: 24px;
    flex: 0 1 auto;
    color: var(--text-0);
  }
}
</style>
