import rest from '@exchange/libs/utils/rest/src';

const URL = '/accounts';

export interface BEEotcAccount {
  account_id: string;
  currency: string;
  available_amount: string;
  locked_amount: string;
}

export default function Resource({ base }) {
  return {
    getAll(): Promise<Array<BEEotcAccount>> {
      return rest.request({
        url: URL,
        base,
      });
    },
  };
}
