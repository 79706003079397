import rest from '@exchange/libs/utils/rest/src';

const URL = '/verification-information';

export const verificationStatuses = {
  NOT_VERIFIED: 'NOT_VERIFIED',
  VERIFICATION_STARTED: 'VERIFICATION_STARTED',
  VERIFICATION_SUCCEEDED: 'VERIFICATION_SUCCEEDED',
} as const;
export type VerificationStatus = (typeof verificationStatuses)[keyof typeof verificationStatuses];
export type VerificationInformation = {
  verification: {
    status: VerificationStatus;
    verification_date: string | null;
  };
  aml: {
    date: string | null;
  };
};

export default function Resource({ base }) {
  return {
    get: (): Promise<VerificationInformation> =>
      rest.request(
        {
          url: URL,
          base,
        },
        {
          useSubaccount: false,
        },
      ),
  };
}
