import rest from '@exchange/libs/utils/rest/src';

const URL = '/user/feature-restrictions';

export default function AccountRestrictionsResource({ base }) {
  return {
    get(): Promise<string[]> {
      return rest.request(
        {
          url: `${base}/${URL}`,
          base,
        },
        {
          useSubaccount: false,
        },
      );
    },
  };
}
