import type { CurrencyPrice } from '@exchange/libs/utils/currency/src';
import rest from '@exchange/libs/utils/rest/src';

const URL = '/getPrices';

export default function Resource({ base }) {
  const pathURL = `${base}${URL}`;

  return {
    get(): Promise<Array<CurrencyPrice>> {
      return rest.unAuthorizedRequest({
        url: pathURL,
        base,
      });
    },
  };
}
