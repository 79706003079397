import { SubaccountLimits } from '@exchange/libs/trading-accounts/service/src';
import type { BESubaccountLimits } from '@exchange/libs/trading-accounts/service/src';
import rest from '@exchange/libs/utils/rest/src';

const URL = '/limits';

export default function LimitsResource({ base, subaccountUrl }) {
  const limitsUrl = `${subaccountUrl}${URL}`;

  return {
    async get() {
      const res: BESubaccountLimits = await rest.request(
        {
          url: limitsUrl,
          base,
        },
        {
          useSubaccount: false,
        },
      );

      return new SubaccountLimits(res);
    },
  };
}
