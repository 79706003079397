<script lang="ts" setup>
import { computed, type PropType } from 'vue';

import { usePromiseThrowing } from '@exchange/libs/composables/shared/src/lib/usePromise';
import { modalVariant, type ModalVariant } from '@exchange/libs/modals/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

const props = defineProps({
  confirm: { type: Function as PropType<() => Promise<void>>, required: true },
  cancel: { type: Function as PropType<() => Promise<void>>, required: true },
  canClose: { type: Boolean, default: false },
  variant: { type: String as PropType<ModalVariant>, default: modalVariant.dark },
});

const { loading: canceling, createPromise: cancelUpdate } = usePromiseThrowing(async () => {
  await props.cancel().catch((e) => {
    logger.error('Native update cancellation failed', e);
  });
});

const { loading: confirming, createPromise: confirmUpdate } = usePromiseThrowing(async () => {
  await props.confirm().catch((e) => {
    logger.error('Native update confirmation failed', e);
  });
});

const disableButtonActions = computed(() => canceling.value || confirming.value);
</script>

<template>
  <x-modal
    class="native-app-update-available"
    type="info"
    :variant="variant"
    :title="$t('modules.nativeApps.updateAvailable.title')"
    :can-close="canClose"
    @modal-close="cancelUpdate"
  >
    <template #content>
      <p>{{ $t('modules.nativeApps.updateAvailable.message') }}</p>
    </template>
    <template #buttons>
      <x-button
        v-if="canClose"
        variant="outline"
        :text="$t('modules.nativeApps.updateAvailable.cancel')"
        :loading="canceling"
        :disabled="disableButtonActions"
        @click="cancelUpdate"
      />
      <x-button
        variant="primary"
        :loading="confirming"
        :disabled="disableButtonActions"
        :text="$t('modules.nativeApps.updateAvailable.confirm')"
        @click="confirm"
      />
    </template>
  </x-modal>
</template>
