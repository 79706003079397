import { nanoid } from 'nanoid';

import getTimestampNumber from '@exchange/helpers/date-to-milliseconds-since-epoch';
import { WSIncomingEventTypes, wsSportChannelNames, type WSIncomingBasicMessagePayload } from '@exchange/libs/utils/wss/src/lib/websocket-spot-model';

/* eslint-disable camelcase */
export interface PricetickMessage extends WSIncomingBasicMessagePayload {
  channel_name: typeof wsSportChannelNames.PRICE_TICKS;
  instrument_code: string;
}

export type TakerSid = 'BUY' | 'SELL';

export interface PricetickSnapshot extends PricetickMessage {
  amount: string;
  best_ask: string;
  best_bid: string;
  price: string;
  sequence: number;
  taker_side: TakerSid;
  time: string;
}
/* eslint-enable camelcase */

export interface PricetickHistorySnapshot extends PricetickMessage {
  history: Array<PricetickSnapshot>;
  type: WSIncomingEventTypes.PRICE_TICK_HISTORY;
}

export class PricetickModel {
  public internalId: string;

  public takerSide: TakerSid;

  public amount: number;

  public price: number;

  public bestAskPrice: number;

  public bestBidPrice: number;

  public time: Date;

  public timeString: string;

  public tzTime: number;

  public sequence: number;

  public instrumentCode: string;

  public get isBuy() {
    return this.takerSide && this.takerSide === 'BUY';
  }

  constructor(fields: PricetickSnapshot) {
    this.internalId = nanoid();
    this.takerSide = fields.taker_side;
    this.amount = Number(fields.amount);
    this.price = Number(fields.price);
    this.bestAskPrice = Number(fields.best_ask);
    this.bestBidPrice = Number(fields.best_bid);
    this.instrumentCode = fields.instrument_code;
    this.sequence = Number(fields.time);
    this.time = new Date(getTimestampNumber(fields.time));
    this.timeString = fields.time;
    this.tzTime = new Date(getTimestampNumber(fields.time)).getTime();
  }
}
