import { ExportItem, BEExport } from '@exchange/libs/exports/service/src';
import rest from '@exchange/libs/utils/rest/src';

export default function ExportResources({ base, exportURL }) {
  return {
    async get() {
      const res: BEExport = await rest.request(
        {
          url: exportURL,
          base,
        },
        {
          useSubaccount: false,
        },
      );

      return new ExportItem(res);
    },
    async download() {
      const { data, headers } = await rest.request(
        {
          url: `${exportURL}/download`,
          base,
        },
        {
          useSubaccount: false,
          respondWithReaders: true,
        },
      );

      return {
        data,
        filename: headers['content-disposition'],
      };
    },
  };
}
