import rest from '@exchange/libs/utils/rest/src';

const URL = '/firebase';

export default function FirebaseResource({ base }) {
  return {
    get() {
      return rest.request(
        {
          url: URL,
          base,
        },
        {
          useSubaccount: false,
        },
      );
    },
  };
}
