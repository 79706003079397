import debounce from 'lodash/debounce';
import { isEmpty, difference as arrayDiff, negate, orderBy } from 'lodash/fp';
import { computed, reactive, watch } from 'vue';

import BigNumber from '@exchange/helpers/bignumber';
import { getFirstItemsInChunks } from '@exchange/helpers/get-first-items-in-chunks';
import { forEach } from '@exchange/helpers/lodash-fp-no-cap';
import difference, { noDifference } from '@exchange/helpers/object-diff';
import percentFormatting from '@exchange/helpers/percent-formatting';
import { persistValue, syncWithStorage } from '@exchange/helpers/persistance-helper';
import { AggregationOperation } from '@exchange/libs/orderbook/service/src';
import orderbookService from '@exchange/libs/orderbook/service/src/lib/orderbook.service';
import CryptoInfoRest from '@exchange/libs/rest-api/crypto-info-api';
import PublicRest from '@exchange/libs/rest-api/public-api';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';
import { WSIncomingEventTypes, WSIncomingEventTypesSpecial } from '@exchange/libs/utils/wss/src/lib/websocket-spot-model';

import { doesCurrencyHaveMarket } from './helpers';
import {
  type BEMarketTicker,
  type MarketDataToCompare,
  MarketModel,
  MarketState,
  type MarketUpdate,
  type WSSMarketTickerUpdate,
  type WSSMarketTickerUpdateMessage,
  type WSSMarketUpdateMessage,
} from './market-model';
import marketTickerChannel from './MarketTicker.channel';

export type MarketLinkInfo = { text: string; id: string };
export type CurrenciesToMarkets = Dictionary<{ primary?: Array<MarketLinkInfo>; secondary?: Array<MarketLinkInfo> }>;

interface MarketsState {
  markets: Record<string, MarketModel>;
  instrumentsFromFirstMarketTick: Array<string>;
  selectedMarketIdSpot: string;
}

class Markets {
  private readonly logPrefix = 'MarketService:';

  private getDefaultState = () => ({
    markets: {},
    instrumentsFromFirstMarketTick: [],
    selectedMarketIdSpot: syncWithStorage(CONSTANTS.SELECTED_MARKET, CONSTANTS.DEFAULT_MARKET_ID),
  });

  private marketTickerUpdates = new Map<string, WSSMarketTickerUpdate>();

  private readonly state = reactive<MarketsState>(this.getDefaultState());

  public markets = computed(() => this.state.markets);

  public instrumentsFromFirstMarketTick = computed(() => this.state.instrumentsFromFirstMarketTick);

  public selectedMarketIdSpot = computed(() => this.state.selectedMarketIdSpot);

  public getMarket = (marketId?: string) => {
    if (!marketId) {
      return undefined;
    }

    if (this.state.markets[marketId]) {
      return this.state.markets[marketId];
    }

    return undefined;
  };

  public getMarketName = (market: MarketModel | undefined) => market?.name;

  public getMarketState = (market: MarketModel | undefined) => market?.state;

  public getMarketLastPrice = (market: MarketModel | undefined) => market?.lastPrice ?? NaN;

  public getMarketPrecision = (market: MarketModel | undefined) => ({
    amount: market?.amountPrecision ?? 0,
    market: market?.marketPrecision ?? 0,
    total: market?.totalPrecision ?? 0,
  });

  public marketDataLoading = computed(() => {
    if (isEmpty(this.state.instrumentsFromFirstMarketTick) || isEmpty(this.state.markets)) {
      return true;
    }

    return !this.state.instrumentsFromFirstMarketTick
      .reduce((acc: Array<number>, instrument: string) => {
        const market = this.state.markets[instrument];

        if (market) {
          acc.push(market.allDay.volume);
        }

        return acc;
      }, [])
      .every(negate(Number.isNaN));
  });

  private marketTickerChannelSubscription: (() => Promise<void>) | null = null;

  public doesMarketExistForCurrency = (currencyCode: string) => {
    const ids = Object.keys(this.markets.value);

    return doesCurrencyHaveMarket(ids)(currencyCode);
  };

  public awaitMarkets = (): Promise<Dictionary<MarketModel>> => {
    if (!isEmpty(this.state.markets)) {
      return Promise.resolve(this.state.markets);
    }

    return new Promise((resolve) => {
      const watchStopHandle = watch(
        () => this.state.markets,
        // eslint-disable-next-line consistent-return
        (newValue) => {
          if (newValue) {
            watchStopHandle();
            return resolve(newValue);
          }
        },
      );
    });
  };

  public getMarketTicker = async (marketId: string) => PublicRest.MarketTicker.getInstrument(marketId);

  public getMarketsTicker = async () => PublicRest.MarketTicker.get();

  private marketsTickerPollingTimeoutID = 0;

  private marketsTickerPollingTimeoutMs = 30_000;

  private marketsTickerPolling = async () => {
    if (this.marketsTickerPollingTimeoutID) {
      this.marketsTickerPollingStop();
    }

    const data = await this.getMarketsTicker().catch((e) => {
      logger.error(`${this.logPrefix} Polling error`, e);
      return [];
    });
    const mappedData = data.map((i: BEMarketTicker) => ({
      high: i.high,
      instrument: i.instrument_code,
      last_price: i.last_price,
      low: i.low,
      price_change_percentage: i.price_change_percentage,
      price_change: i.price_change,
      price_points: [],
      state: i.state,
      volume: i.quote_volume,
    }));

    const coverMarketTickerUpdate = () => {
      this.upsertMarketTicker(mappedData);
    };
    const coverMarketUpdate = () => {
      /** with available via REST data it is only possible to cover/handle market state change */

      const shouldCallInstrumentsToGetMarketsDetails = mappedData.reduce((acc, m) => {
        const stateMarket = this.state.markets[m.instrument];

        if (!stateMarket) {
          // new market
          if (m.state === MarketState.CLOSED) {
            return acc;
          }

          return true;
        }

        if (stateMarket.state !== m.state) {
          // market state changed, but we dont have precision info => should call instruments
          return true;
        }

        return acc;
      }, false);

      if (shouldCallInstrumentsToGetMarketsDetails) {
        logger.info(`${this.logPrefix} instruments mismatch /market-ticker vs /instruments`);
        // this.fetchMarkets(); // TODO uncomment when /instruments includes markets returned by /market-ticker
      }
    };

    coverMarketTickerUpdate();
    coverMarketUpdate();

    this.marketsTickerPollingTimeoutID = window.setTimeout(this.marketsTickerPolling, this.marketsTickerPollingTimeoutMs);
  };

  public marketsTickerPollingStop = () => {
    clearTimeout(this.marketsTickerPollingTimeoutID);
  };

  private removeMeInFuture = true; // TODO needs to be removed in future

  async subscribeToMarketTicker(markets: Dictionary<MarketModel>) {
    try {
      if (this.removeMeInFuture) {
        this.marketsTickerPolling();

        return;
      }

      await this.marketTickerChannelSubscription?.();
      this.state.instrumentsFromFirstMarketTick = [];

      await new Promise<void>((res, rej) => {
        this.marketTickerChannelSubscription = marketTickerChannel.subscribe(
          {
            markets: Object.keys(markets),
            onMessage: (message) => {
              switch (message.type) {
                case WSIncomingEventTypes.MARKET_TICKER_UPDATES:
                  this.upsertMarketTicker((message as WSSMarketTickerUpdateMessage).ticker_updates);
                  break;
                case WSIncomingEventTypes.MARKET_UPDATES:
                  this.processMarketUpdate((message as WSSMarketUpdateMessage).update);
                  break;
                case WSIncomingEventTypesSpecial.UNSUBSCRIBED:
                  break;
                case WSIncomingEventTypes.ERROR:
                  logger.error(`${this.logPrefix} WSS ERROR`, message);
                  break;
                default:
                  logger.warn(`${this.logPrefix} Unknown message type`, message.type);
              }
            },
          },
          {
            success: () => res(),
            fail: (error) => rej(error),
          },
        );
      });
    } catch (error) {
      logger.warn(`${this.logPrefix} Subscribing failed; retrying later`, error);
      await this.marketTickerChannelSubscription?.();
      await retryService.waitForNextRetryTick();
      await this.subscribeToMarketTicker(markets);
    }
  }

  private setMarketsAndSubscribeToMarketTicker = async (markets: Record<string, MarketModel>) => {
    this.subscribeToMarketTicker(markets);

    /* first time */
    if (isEmpty(this.state.markets)) {
      this.state.markets = markets;
      return;
    }

    /* after nap */
    const newMarketsKeys = Object.keys(markets);
    const stateMarketKeys = Object.keys(this.state.markets);

    const newMarkets = newMarketsKeys.reduce((acc: Record<string, MarketModel>, marketName: string) => {
      const stateMarket = this.state.markets[marketName];
      const market = markets[marketName];

      if (!market) {
        return acc;
      }

      if (stateMarket) {
        const incomingMarketData = market.getDataToCompare();

        forEach((value, key) => {
          stateMarket[key] = value;
        }, incomingMarketData);
      } else {
        acc[marketName] = market;
      }

      return acc;
    }, {});

    if (!isEmpty(newMarkets)) {
      forEach((market: MarketModel, marketName: string) => {
        this.state.markets[marketName] = market;
      }, newMarkets);
    }

    const removedNames = arrayDiff(stateMarketKeys, newMarketsKeys);

    if (removedNames.length) {
      removedNames.forEach((name) => {
        delete this.state.markets[name];
      });
    }
  };

  public fetchMarkets = async () => {
    try {
      const markets: Record<string, MarketModel> = (await PublicRest.Instruments.get())
        .filter((market) => market.state !== MarketState.CLOSED)
        .sort((a, b) => {
          const aBC = MarketModel.getMarketId(a.base.code, a.quote.code);
          const mBC = MarketModel.getMarketId(b.base.code, b.quote.code);

          if (aBC < mBC) {
            return -1;
          }

          if (aBC > mBC) {
            return 1;
          }

          return 0;
        })
        .reduce((acc, market) => {
          const mm = new MarketModel(market);

          acc[mm.id] = mm;
          return acc;
        }, {});

      await this.setMarketsAndSubscribeToMarketTicker(markets);
    } catch (error) {
      logger.warn(`${this.logPrefix} Market fetching failed; retrying later`, error);
      await retryService.waitForNextRetryTick();
      await this.fetchMarkets();
    }
  };

  public getValidMarketId = async ({ marketId, forFutures }: { marketId: string; forFutures?: boolean }): Promise<string> => {
    let selectedMarketId = marketId;

    const marketStorageData = forFutures
      ? {
          key: CONSTANTS.SELECTED_MARKET_FUTURES,
          default: CONSTANTS.DEFAULT_MARKET_ID_FUTURES,
        }
      : {
          key: CONSTANTS.SELECTED_MARKET,
          default: CONSTANTS.DEFAULT_MARKET_ID,
        };

    if (isEmpty(this.state.markets)) {
      logger.warn(`${this.logPrefix} Markets have not been loaded yet; retrying later`);
      await retryService.waitForNextRetryTick();

      return this.getValidMarketId({ marketId: selectedMarketId });
    }

    if (!this.state.markets[selectedMarketId]) {
      selectedMarketId = syncWithStorage(marketStorageData.key, marketStorageData.default);

      if (!this.state.markets[selectedMarketId]) {
        persistValue(marketStorageData.key)(marketStorageData.default);
        selectedMarketId = marketStorageData.default;
      }
    }

    if (!this.state.markets[selectedMarketId]) {
      logger.error(`${this.logPrefix} Markets do not include selected market ${selectedMarketId}`);
      selectedMarketId = Object.keys(this.state.markets)?.[0] || ''; // should never be empty because of the check earlier

      return this.getValidMarketId({ marketId: selectedMarketId });
    }

    persistValue(marketStorageData.key)(selectedMarketId);

    if (!forFutures) {
      this.state.selectedMarketIdSpot = selectedMarketId;
    }

    return selectedMarketId;
  };

  // TODO check if we should debounce it at all
  private updateMarketData = debounce(
    () => {
      this.marketTickerUpdates.forEach((update: WSSMarketTickerUpdate) => {
        const market = this.state.markets[update.instrument];

        if (market) {
          this.marketTickerUpdates.delete(update.instrument);

          market.lastPrice = Number(update.last_price);
          market.allDay.high = Number(update.high);
          market.allDay.low = Number(update.low);
          market.allDay.volume = Number(update.volume);
          market.allDay.priceChange = Number(update.price_change);
          market.allDay.priceChangePercentage = Number(update.price_change_percentage);
          market.allDay.pricePointData = update.price_points?.map(({ price }) => price) || [];

          const pricePointDataLength = market.allDay.pricePointData.length;

          if (pricePointDataLength) {
            market.allDay.pricePointData[pricePointDataLength - 1] = update.last_price;
          }
        }
      });
    },
    200,
    { maxWait: 500 },
  );

  public upsertMarketTicker = (tickerUpdates: Array<WSSMarketTickerUpdate>) => {
    if (!this.state.instrumentsFromFirstMarketTick.length) {
      this.state.instrumentsFromFirstMarketTick = tickerUpdates.map((u) => u.instrument);
    }

    tickerUpdates.forEach((update: WSSMarketTickerUpdate) => {
      this.marketTickerUpdates.set(update.instrument, update);
    });

    this.updateMarketData();
  };

  private processMarketUpdate = async (update: MarketUpdate) => {
    const marketId = MarketModel.getMarketId(update.base.code, update.quote.code);
    const market = new MarketModel(update);
    const stateMarket = this.state.markets[marketId];

    if (!stateMarket) {
      // new market
      if (market.state === MarketState.CLOSED) {
        return;
      }

      await this.setMarketsAndSubscribeToMarketTicker({
        ...this.state.markets,
        [market.id]: market,
      });

      return;
    }

    const stateMarketData = stateMarket.getDataToCompare();
    const incomingMarketData = market.getDataToCompare();
    const hasMarketChanged = !noDifference(stateMarketData, incomingMarketData);

    if (hasMarketChanged) {
      if (update.state === MarketState.CLOSED) {
        const { [marketId]: omit, ...markets } = this.state.markets;

        await this.setMarketsAndSubscribeToMarketTicker(markets);

        return;
      }

      const marketDiff = difference(incomingMarketData, stateMarketData) as MarketDataToCompare;

      forEach((value, key) => {
        stateMarket[key] = value;
      }, marketDiff);

      if (marketDiff.marketPrecision || marketDiff.amountPrecision) {
        (await orderbookService.getOrderbook({ id: marketId, precision: stateMarket.marketPrecision })).value.doAggregation(
          AggregationOperation.RESET,
          stateMarket.marketPrecision,
        );
      }
    }
  };

  public updateLastPrice = ({ lastPrice, marketId }: { lastPrice: string; marketId: string }) => {
    const market = this.state.markets[marketId];

    if (market) {
      market.lastPrice = Number(lastPrice);
    }
  };

  public getCurrenciesToMarkets = (markets: Dictionary<MarketModel>) =>
    Object.values(markets).reduce(
      (acc, market: MarketModel | undefined) => {
        if (!market) {
          return acc;
        }

        const quoteCode = market.quote.code;
        const baseCode = market.base.code;
        const data = {
          text: market.name,
          id: market.id,
        };

        const objQuoteCode = acc[quoteCode];
        const objBaseCode = acc[baseCode];

        if (objQuoteCode) {
          if (objQuoteCode.secondary) {
            objQuoteCode.secondary.push(data);
          } else {
            objQuoteCode.secondary = [data];
          }
        } else {
          acc[quoteCode] = { secondary: [data] };
        }

        if (objBaseCode) {
          if (objBaseCode.primary) {
            objBaseCode.primary.push(data);
          } else {
            objBaseCode.primary = [data];
          }
        } else {
          acc[baseCode] = { primary: [data] };
        }

        return acc;
      },
      <CurrenciesToMarkets>{},
    );

  public marketStats = {
    get: async () => {
      const defaultPricePrecision = 8;
      const getPriceChange = (firstPrice: string, lastPrice: string) => {
        const first = new BigNumber(firstPrice);
        const last = new BigNumber(lastPrice);
        const diff = last.minus(first);
        const avg = first.plus(last).div(2);

        return {
          percentage: diff.div(avg).times(100),
          isPositive: diff.gt(0),
          isNegative: diff.lt(0),
          isZero: diff.eq(0),
        };
      };
      const getPrecision = (price: string) => {
        if (price === 'NaN') {
          return 0;
        }

        const parts = price.split('.');

        return Math.max(parts[1]?.length ?? defaultPricePrecision, 0);
      };
      let info = await PublicRest.MarketStats.Views.get().catch(() => []);

      info = orderBy(['count', 'instrument'], ['desc'], info);
      const priceChange = await CryptoInfoRest.PriceChange.get(info.map((i) => i.instrument)).catch(() => []);

      return info.map((item) => {
        const [base, quote] = item.instrument.split('_');
        const name = [base, quote].join('/');

        const marketPriceChange = priceChange[item.instrument]?.length ? getFirstItemsInChunks(priceChange[item.instrument], 32) : ['NaN'];

        const first = marketPriceChange.at(0);
        const last = marketPriceChange.at(-1);
        const priceChangePercentageInfo = getPriceChange(first, last);
        const priceChangePercentageToDisplay = percentFormatting(priceChangePercentageInfo.percentage);
        const pricePrecision = getPrecision(last);

        return {
          name,
          base: base ?? '',
          count: item.count,
          lastPrice: last,
          pricePrecision,
          pricePoints: marketPriceChange,
          priceChange: priceChangePercentageInfo,
          priceChangePercentageToDisplay,
        };
      });
    },
    set: (instrument: string, price: string) => PublicRest.MarketStats.Views.set(instrument, price),
  };
}

const marketService = new Markets();

export default marketService;
