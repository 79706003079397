import rest from '@exchange/libs/utils/rest/src';

const URL = '/logout';

export default function LogoutResource({ base }) {
  return {
    send(hint: string) {
      return rest.unAuthorizedRequest({
        url: `${URL}?id_token_hint=${hint}`,
        base,
      });
    },
  };
}
