import AddressesResource from './addresses-resource';
import DetailsResource from './details-resource';

const DEPOSIT_URL = '/account-holder/deposit';

export default function DepositResource({ base, fundingURL }) {
  const url = `${fundingURL}${DEPOSIT_URL}`;

  return {
    Addresses: AddressesResource({ base, url }),
    Details: DetailsResource({ base, url }),
  };
}
