import type { OrderbookRest } from '@exchange/libs/orderbook/service/src';
import rest from '@exchange/libs/utils/rest/src';

const URL = '/order-book';

export default function OrderBookResource({ base }) {
  return {
    getInstrument(instrumentCode: string): Promise<OrderbookRest> {
      return rest.unAuthorizedRequest({
        url: `${URL}/${instrumentCode}`,
        base,
      });
    },
  };
}
