import rest from '@exchange/libs/utils/rest/src';

const URL = '/conversions';

/* eslint-disable camelcase */
export interface BEMConversions {
  instrument_code: string;
  value: string;
}
/* eslint-enable camelcase */

export default function ConversionsResource({ base }) {
  return {
    get(): Promise<Array<BEMConversions>> {
      return rest.unAuthorizedRequest({
        url: URL,
        base,
      });
    },
  };
}
