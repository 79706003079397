import rest from '@exchange/libs/utils/rest/src';

const URL = '/balances';

type Period = 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';

export default function BalancesResource({ base }) {
  return {
    get: (period: Period, currency: string): Promise<Array<{ time: string; value: string }>> =>
      rest.request({
        url: `${URL}/${period}/${currency}`,
        base,
      }),
  };
}
