import { type FundingTransferMethodType, type BETransaction } from '@exchange/libs/transactions/funds/service/src';
import rest from '@exchange/libs/utils/rest/src';

const transactionsURL = 'account-holder/transactions';

export default function TransactionsResource({ base, fundingURL }) {
  const url = `${fundingURL}/${transactionsURL}`;

  return {
    async get(transactionId: string): Promise<BETransaction> {
      return rest.request({
        url: `${url}/${transactionId}`,
        base,
      });
    },

    async getTypes(): Promise<Array<FundingTransferMethodType>> {
      const response = await rest.request({
        url: `${url}/types`,
        base,
      });

      return response;
    },

    async getAll({
      from,
      to,
      cursor,
      direction,
      status,
      currency,
      transferMethod,
      maxPageSize,
    }): Promise<{ cursor: string | undefined; max_page_size: number; transactions: Array<BETransaction> }> {
      return rest.request({
        url,
        params: {
          from,
          to,
          cursor,
          direction,
          ...(currency && { currencies: currency.id }),
          ...(transferMethod && { transfer_method: transferMethod }),
          ...(status && { status }),
          max_page_size: maxPageSize,
        },
        base,
      });
    },
  };
}
