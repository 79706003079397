import { computed, ref } from 'vue';

import { authService } from '@exchange/libs/auth/service/src';
import { settingsService } from '@exchange/libs/settings/service/src';
import { usePromoMarkets } from '@exchange/libs/utils/promos/src';

const useMarketPromotionRibbon = (ldFlag?: string) => {
  const { promoIsActive } = usePromoMarkets(ldFlag);
  const ribbonClosed = ref(false);

  const marketPromotionSettings = computed(() => settingsService.settings.userSettings.marketPromotion?.timestamp);

  const showMarketPromotionRibbon = computed(() => {
    if (!authService.initialLoadAuthIsChecked) {
      return false;
    }

    if (!authService.isAuthenticated) {
      return !ribbonClosed.value && promoIsActive.value;
    }

    return promoIsActive.value && !marketPromotionSettings.value;
  });

  const onCloseMarketPromotion = async () => {
    if (authService.isAuthenticated) {
      await settingsService.setSettings({
        path: 'marketPromotion.timestamp',
        value: new Date().getTime(),
      });
    }

    ribbonClosed.value = true;
  };

  return {
    showMarketPromotionRibbon,
    onCloseMarketPromotion,
  };
};

export default useMarketPromotionRibbon;
