import rest from '@exchange/libs/utils/rest/src';

const URL = '/switch-account';

/* eslint-disable camelcase */
export interface BESwitchResponse {
  access_token: string;
  expires_in: number;
  issued_token_type: string;
  token_type: string;
}
/* eslint-enable camelcase */

export default function SwitchAccountResource({ base }) {
  return {
    request(accountId: string): Promise<BESwitchResponse> {
      return rest.request(
        {
          url: URL,
          method: 'post',
          payload: {
            account_id: accountId,
          },
          base,
        },
        {
          useSubaccount: false,
        },
      );
    },
  };
}
