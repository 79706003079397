import { BEMarketModel } from '@exchange/libs/market/service/src';
import rest from '@exchange/libs/utils/rest/src';

const URL = '/instruments';

export default function InstrumentsResource({ base }) {
  return {
    get(): Promise<Array<BEMarketModel>> {
      return rest.unAuthorizedRequest({
        url: URL,
        base,
      });
    },
  };
}
