import { ref, Ref } from 'vue';

import PriceTicksChannel from '@exchange/libs/price-ticks/service/src/lib/Priceticks.channel';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';
import { type OnReconnectCallback, type SubscribeCallbacks } from '@exchange/libs/utils/wss/src';
import { WSIncomingEventTypes } from '@exchange/libs/utils/wss/src/lib/websocket-spot-model';

import { PricetickHistorySnapshot, PricetickMessage, PricetickModel, PricetickSnapshot } from './pricetick-model';

class PriceTicks {
  private readonly logPrefix = 'PriceTicksService:';

  private data: Dictionary<{
    ticks: Ref<Array<PricetickModel>>;
    buffer: Array<PricetickModel>;
    listenerCount: number;
    unsubscribeTimer: number | undefined;
    snapshotReceived: Ref<boolean>;
    unsubscribe?: () => Promise<void>;
  }> = {};

  private unsubscribe = (marketId: string) => () => {
    const marketData = this.data[marketId];

    if (!marketData) {
      logger.warn(`${this.logPrefix} trying to unsubscribe from ${marketId}, but there is already no data`);
      return;
    }

    marketData.listenerCount -= 1;

    if (marketData.listenerCount < 1) {
      const timer = setTimeout(async () => {
        // TODO ask @oli what he meant to do, without added catch the code can produce unhandled promise rejection!!!
        try {
          // if unsubscribe is undefined something went wrong - so i want it to fail here
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          await marketData.unsubscribe!()
            .then(() => {
              this.clear(marketId);
            })
            .catch((e) => {
              logger.log(`${this.logPrefix} unsubscribing for ${marketId} failed with`, e);
            });
        } catch (e) {
          logger.log(`${this.logPrefix} @oli, it's failed, you wanna check something now`, e);
        }
      }, 5000);

      // @ts-ignore fix it
      marketData.unsubscribeTimer = timer;
    }
  };

  public subscribe(marketId: string, callbacks: SubscribeCallbacks<{ priceTicks; snapshotReceived }>) {
    const getDefaultData = () => ({
      ticks: ref([]),
      buffer: [],
      listenerCount: 1,
      unsubscribeTimer: undefined,
      snapshotReceived: ref(false),
    });

    let marketData = this.data[marketId];

    if (!marketData) {
      marketData = getDefaultData();
      this.data[marketId] = marketData;

      marketData.unsubscribe = this.openChannel(marketId, {
        success: () => {
          let smd = this.data[marketId];

          if (!smd) {
            logger.warn(`${this.logPrefix} open channel succeeded but there is no ${marketId} data, setting defaults...`);
            smd = getDefaultData();
            this.data[marketId] = smd;
          }

          callbacks.success({
            priceTicks: smd.ticks,
            snapshotReceived: smd.snapshotReceived,
          });
        },
        fail: (error) => {
          callbacks.fail(error);
        },
      });
    } else {
      marketData.listenerCount += 1;
      const { unsubscribeTimer } = marketData;

      if (unsubscribeTimer !== undefined) {
        clearTimeout(unsubscribeTimer);
        marketData.unsubscribeTimer = undefined;
      }

      callbacks.success({
        priceTicks: marketData.ticks,
        snapshotReceived: marketData.snapshotReceived,
      });
    }

    return this.unsubscribe(marketId);
  }

  openChannel(marketId: string, callbacks: SubscribeCallbacks) {
    return PriceTicksChannel.subscribe(
      {
        market: marketId,
        onMessage: (e: PricetickMessage) => {
          switch (e.type) {
            case WSIncomingEventTypes.PRICE_TICK:
              this.addPriceTick(e as PricetickSnapshot);
              break;
            case WSIncomingEventTypes.PRICE_TICK_HISTORY:
              this.setPriceTickHistory(e as PricetickHistorySnapshot);
              break;
            default:
              logger.warn(`${this.logPrefix} Unknown PRICE_TICKS channel event type`, e.type);
              break;
          }
        },
      },
      callbacks,
    );
  }

  public onPriceTicksChannelReconnectAdd = (cb: OnReconnectCallback) => {
    PriceTicksChannel.onReconnectAdd(cb);
  };

  public onPriceTicksChannelReconnectDelete = (cb: OnReconnectCallback) => {
    PriceTicksChannel.onReconnectDelete(cb);
  };

  private setPriceTickHistory = async (priceTickHistory: PricetickHistorySnapshot) => {
    const checkSequence = (arr: Array<PricetickSnapshot>) => (tick: PricetickSnapshot, idx: number) => {
      if (idx > 0) {
        const prevTick = arr[idx - 1];

        if (prevTick) {
          this.checkSequenceErrors({ instrumentCode: prevTick.instrument_code, sequence: prevTick.time }, { instrumentCode: tick.instrument_code, sequence: tick.time });
        }
      }
    };

    const history = priceTickHistory.history.slice(-CONSTANTS.PRICE_TICKS_BUNCH_SIZE);
    const checkHistorySequence = checkSequence(history);
    const marketData = this.data[priceTickHistory.instrument_code];

    if (marketData?.snapshotReceived.value) {
      logger.warn(`${this.logPrefix} extra snapshot is received ${priceTickHistory.instrument_code}`);
      return;
    }

    if (marketData?.ticks.value.length) {
      history.forEach((tick, idx) => {
        checkHistorySequence(tick, idx);
        this.addPriceTick(tick, true);
      });
    } else {
      const ticks = history
        .map((tick, idx) => {
          checkHistorySequence(tick, idx);
          return new PricetickModel(tick);
        })
        .reverse();

      if (marketData) {
        marketData.buffer = [];
        marketData.ticks.value = ticks;
      }
    }

    if (marketData) {
      marketData.snapshotReceived.value = true;
    }
  };

  private processPriceTicks = (marketId: string) => {
    const marketData = this.data[marketId];

    if (!marketData) {
      // wss was unsubscribed
      return;
    }

    const priceTicks = marketData.buffer;

    marketData.buffer = [];
    const firstTickSequence = marketData.ticks.value[0]?.sequence;

    const validTicksFromBuffer = priceTicks.reduce(
      (acc, tick) => {
        if (firstTickSequence === undefined || (firstTickSequence !== undefined && tick.sequence > firstTickSequence)) {
          acc.push(tick);
        }

        return acc;
      },
      <Array<PricetickModel>>[],
    );

    marketData.ticks.value = [...validTicksFromBuffer, ...marketData.ticks.value].slice(0, CONSTANTS.PRICE_TICKS_BUNCH_SIZE);
  };

  // @ts-ignore use once sequence is available
  private checkSequenceErrors(prevTick: { instrumentCode: string; sequence: string } | undefined, latestTick: { instrumentCode: string; sequence: string }) {
    // No need to report if no prevTick or marketIDs mismatch after market change
    if (!prevTick || prevTick.instrumentCode !== latestTick.instrumentCode) {
      return;
    }

    // No need to report if sequence numbers are correct
    if (latestTick.sequence > prevTick.sequence) {
      return;
    }

    const marketId = latestTick.instrumentCode;
    const marketData = this.data[marketId];

    if (!marketData) {
      logger.warn(`${this.logPrefix} cannot perform check sequence, there is no data for ${marketId}`);
      return;
    }

    const bl = marketData.buffer.length - 1;
    const lastBI = bl >= 0 ? marketData.buffer?.[bl]?.sequence : undefined;

    logger.log(`${this.logPrefix}         buffer[0]`, marketData.buffer[0]?.sequence, 'ticks', marketData.ticks.value[0]?.sequence, `buffer[${bl}]`, lastBI);
    logger.log(`${this.logPrefix} prevTick.sequence`, prevTick.sequence, 'latestTick.sequence', latestTick.sequence);

    // Change error string query in datadog alert as well if you modify the string below
    // or hooked up datadog alert will not trigger
    const datadogAlertQuery = `${this.logPrefix} SEQUENCE MISSING ERROR between: ${prevTick.sequence} - ${latestTick.sequence}`;

    // Sequence error sent to datadog
    logger.error(new Error(datadogAlertQuery), { prevTick, latestTick });
  }

  public getLastPriceTick(marketId: string) {
    return this.data[marketId]?.buffer[0] ?? this.data[marketId]?.ticks.value[0];
  }

  public addPriceTick = (priceTickSnapshot: PricetickSnapshot, ignoreSequence = false) => {
    const priceTick = new PricetickModel(priceTickSnapshot);

    if (!ignoreSequence) {
      const latest = this.getLastPriceTick(priceTickSnapshot.instrument_code);

      if (latest) {
        this.checkSequenceErrors(
          { instrumentCode: latest.instrumentCode, sequence: latest?.timeString },
          { instrumentCode: priceTick.instrumentCode, sequence: priceTick.timeString },
        );
      }
    }

    this.data[priceTick.instrumentCode]?.buffer.unshift(priceTick);

    if (this.data[priceTick.instrumentCode]?.buffer.length === 1) {
      window.requestIdleCallback(() => {
        this.processPriceTicks(priceTick.instrumentCode);
      });
    }
  };

  private clear(marketId: string) {
    logger.info(`${this.logPrefix} deleting ${marketId} data`);
    delete this.data[marketId];
  }
}

const priceTicksService = new PriceTicks();

export default priceTicksService;
