import type { BEFundingTransferMethodFields, BEFundingTransferMethods, FundingTransferMethodType } from '@exchange/libs/transactions/funds/service/src';
import rest from '@exchange/libs/utils/rest/src';

const transferURL = 'transfer-methods';
const accountHolderURL = 'account-holder';

export default function TransferMethodResource({ base, fundingURL }) {
  const url = `${fundingURL}/${accountHolderURL}/${transferURL}`;

  return {
    async get(currencyCode: string) {
      const response: Promise<BEFundingTransferMethods> = await rest.request({
        url: `${url}/${currencyCode}`,
        base,
      });

      return response;
    },

    async getAll() {
      const response: Promise<BEFundingTransferMethods> = await rest.request({
        url,
        base,
      });

      return response;
    },

    async getFields(transferMethod: FundingTransferMethodType): Promise<BEFundingTransferMethodFields> {
      const response = await rest.request({
        url: `${fundingURL}/${transferURL}/${transferMethod}/fields`,
        base,
      });

      return response;
    },
  };
}
