import rest from '@exchange/libs/utils/rest/src';

const URL = '/getCoinInfo';

export default function Resource({ base }) {
  const infoURL = `${base}${URL}`;

  return {
    get(symbol: string): Promise<{ symbol: string; network: string }> {
      return rest.unAuthorizedRequest({
        url: `${infoURL}/${symbol}`,
        base,
      });
    },
  };
}
