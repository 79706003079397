import { syncRefs } from '@vueuse/core';
import { computed, onBeforeUnmount, ref, Ref, watch, WatchStopHandle } from 'vue';

import { marketService } from '@exchange/libs/market/service/src';
import { launchdarkly } from '@exchange/libs/utils/launchdarkly/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import type { Orderbook } from './orderbook-model';
import { AggregationOperation } from './orderbook-model/interfaces';
import orderbookService from './orderbook.service';

export default function useOrderbook(marketId: Ref<string>) {
  const orderbook = ref<Orderbook>();
  const orderbookAddedTime = ref<number>();
  const unsubscribe = ref<() => void>();
  const orderbookSnapshotReceived = ref(false);
  const orderbookIsLoading = computed(() => !orderbookSnapshotReceived.value);

  const hasAnimations = computed(() => orderbookService.hasAnimations.value);
  const msPerFrame = computed(() => {
    const obFPS = (launchdarkly.flags['orderbook-fps'].value ?? hasAnimations.value) ? 30 : 15;

    return 1000 / obFPS;
  });

  // we need this in case the component gets unmounted while the orderbook connections is being created.
  // but its very bad that we have to hanlde this - RXJS would fix this issue as it allows us to unsubscribe before the promise is resolved to just cancel everything.
  let unmounted = false;

  let stopOb: WatchStopHandle;
  let stopAt: WatchStopHandle;
  let stopOsr: WatchStopHandle;

  const safeUnsubscribe = (mid?: string) => {
    try {
      unsubscribe.value?.();
    } catch (e) {
      logger.log('Orderbook: unsubscribing failed', e, mid);
    }
  };

  const handleSubscribe = async ({ id, precision }: { id: string; precision: number }) => {
    stopOb?.();
    stopAt?.();
    stopOsr?.();

    safeUnsubscribe(id);
    orderbookSnapshotReceived.value = false;

    unsubscribe.value = orderbookService.subscribe(
      { id, precision },
      {
        success: (res: { orderbook: Ref<Orderbook | undefined>; addedTime: Ref<number | undefined>; snapshotReceived: Ref<boolean> }) => {
          stopOb = syncRefs(res.orderbook, orderbook);
          stopAt = syncRefs(res.addedTime, orderbookAddedTime);
          stopOsr = syncRefs(res.snapshotReceived, orderbookSnapshotReceived);

          if (unmounted) {
            safeUnsubscribe(id);
          }
        },
        fail: (error) => {
          logger.error(`Orderbook: failed to subscribe to ${id}, retrying...`, error);
          handleSubscribe({ id, precision });
        },
      },
    );
  };

  watch(
    marketId,
    async (mid, oldMid) => {
      if (!mid) {
        return;
      }

      const markets = await marketService.awaitMarkets();
      const { marketPrecision } = markets[mid] || { marketPrecision: 0 };

      if (oldMid && mid !== oldMid) {
        orderbook.value?.doAggregation(AggregationOperation.RESET);
      }

      await handleSubscribe({ id: mid, precision: marketPrecision });
    },
    { immediate: true },
  );

  onBeforeUnmount(() => {
    unmounted = true;
    safeUnsubscribe();
  });

  return {
    msPerFrame,
    orderbook,
    orderbookIsLoading,
    orderbookAddedTime,
    hasAnimations,
    orderbookResubscribe: () => orderbookService.restartOrderbooks(),
  };
}

export const useOrderbookAggregation = (orderbook: Ref<Orderbook | undefined>) => {
  const aggregationReducePossible = computed(() => Boolean(orderbook.value?.canDoAggregation(AggregationOperation.REDUCE)));
  const aggregationRaisePossible = computed(() => Boolean(orderbook.value?.canDoAggregation(AggregationOperation.RAISE)));

  const rowAggregationLevel = computed(() => {
    if (orderbook.value?.currentAggregationLevel !== undefined) {
      return orderbook.value.currentAggregationLevel;
    }

    return 0;
  });

  const aggregationLevelCommaPosition = computed(() => {
    if (orderbook.value?.possibleAggregationsLevels) {
      return Number(orderbook.value.possibleAggregationsLevels[rowAggregationLevel.value]);
    }

    return 0;
  });
  const aggregationLevel = computed(() => {
    if (aggregationReducePossible.value) {
      return aggregationLevelCommaPosition.value.toFixed(Math.max(-rowAggregationLevel.value, 0));
    }

    return NaN;
  });

  const aggregationExecute = (operation: AggregationOperation, marketPrecision?: number | undefined) => orderbook.value?.doAggregation(operation, marketPrecision);

  return {
    aggregationLevel,
    aggregationRaisePossible,
    aggregationReducePossible,
    aggregationExecute,
  };
};
