import { currencyService } from '@exchange/libs/utils/currency/src';

import type { MarketModel } from './market-model';

export type MarketFilter = (market: MarketModel) => boolean;

const getFullCoinName = (market: MarketModel) => {
  const currencies = currencyService.currencies.value;

  if (currencies && market?.quote.code && market?.base.code) {
    const quoteName = currencies[market.quote.code]?.name || '';
    const baseName = currencies[market.base.code]?.name || '';

    return `${quoteName} ${baseName}`.toUpperCase();
  }

  return '';
};

const prepareForSearch = (str?: string) => str?.toUpperCase() || '';

export const filterMarketBasedOnSearchStr = (str?: string): MarketFilter => {
  const searchString = prepareForSearch(str);

  return (market: MarketModel) => market.name.includes(searchString) || getFullCoinName(market).includes(searchString);
};

export const filterMarketBasedOnSearchStrExactNameOnly = (str?: string): MarketFilter => {
  const searchString = prepareForSearch(str);

  return (market: MarketModel) => market.name.includes(searchString) || getFullCoinName(market).includes(searchString);
};

export const filterMarketByCurrency = (currency: string): MarketFilter => {
  const filterCurrency = prepareForSearch(currency);

  return (market: MarketModel): boolean => market.quote.code === filterCurrency || market.base.code === filterCurrency;
};
