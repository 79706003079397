import BigNumber from '@exchange/helpers/bignumber';

import { OrderbookSide } from './interfaces';
import type { PriceLevel, OrderbookRest } from './interfaces';

/**
 * Simple Orderbook model
 *
 * To use with the warning modal
 * if the orderbook snapshot from WS
 * are not present.
 */
export default class SimpleOrderbook {
  public bids: Array<PriceLevel> = [];

  public asks: Array<PriceLevel> = [];

  constructor(public marketPrecision: number) {}
}

export class StaticOrderbook extends SimpleOrderbook {
  constructor(
    public override marketPrecision: number,
    snapshot: OrderbookRest,
  ) {
    super(marketPrecision);

    this.bids = this.setLevels(snapshot.bids, OrderbookSide.BID);
    this.asks = this.setLevels(snapshot.asks, OrderbookSide.ASK);
  }

  private setLevels(levels, side: OrderbookSide) {
    const ret: Array<PriceLevel> = [];

    levels.forEach((e) => {
      ret.push({
        amount: +e.amount,
        price: +e.price,
        side,
        total: new BigNumber(+e.amount).times(+e.price).toNumber(),
      });
    });

    return ret;
  }
}
