import identityResource from './identity';
import notificationResource from './notification';

const API_DOMAIN = process.env.VUE_APP_API_DOMAIN_FAST;
const API_SUFFIX = 'customer';
const API_VERSION = 'v1';
const API_BASE = `${API_DOMAIN}/${API_VERSION}/${API_SUFFIX}`;

export const oAuthRest = {
  Identity: identityResource({ base: API_BASE }),
  Notification: notificationResource({ base: API_BASE }),
};

export default oAuthRest;

//
