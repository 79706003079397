import rest from '@exchange/libs/utils/rest/src';

const URL = '/getGeo';

export default function Resource({ base }) {
  const pathURL = `${base}${URL}`;

  return {
    get(): Promise<{ isoCode: string }> {
      return rest.unAuthorizedRequest({
        url: pathURL,
        base,
      });
    },
  };
}
