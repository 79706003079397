import FirebaseResource from './firebase-resource';
import LogoutResource from './logout-resource';
import SwitchAccountResource from './switch-account-resource';

const API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
const API_SUFFIX = 'oauth2';
const API_FAST_DOMAIN = process.env.VUE_APP_API_DOMAIN_FAST;

const API_BASE = `${API_DOMAIN}/${API_SUFFIX}`;
const API_FAST_BASE = `${API_FAST_DOMAIN}/${API_SUFFIX}`;

export const oAuthRest = {
  FirebaseResource: FirebaseResource({ base: API_BASE }),
  Logout: LogoutResource({ base: API_BASE }),
  SwitchAccount: SwitchAccountResource({ base: API_FAST_BASE }),
};

export default oAuthRest;
