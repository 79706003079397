import rest from '@exchange/libs/utils/rest/src';

const OWN_URL = '/transfer';

export type EotcTransferDirection = 'exchange_to_eotc' | 'eotc_to_exchange';
export interface TransferPayload {
  amount: string;
  currencyCode: string;
  direction: EotcTransferDirection;
}

export default function Resource({ base, url }) {
  const transferUrl = `${url}${OWN_URL}`;

  return {
    post(data: TransferPayload) {
      const payload = {
        amount: data.amount,
        currency_code: data.currencyCode,
        direction: data.direction,
      };

      return rest.request(
        {
          url: transferUrl,
          payload,
          method: 'post',
          base,
        },
        {
          useSubaccount: false,
        },
      );
    },
  };
}
