import { OrderFormPayload } from '@exchange/libs/order/order-form/service/src';
import type { OrderTradesPair } from '@exchange/libs/order/shared-model/src/lib/order-model';
import type { BETradeHistoryEntry } from '@exchange/libs/trade/trade-history/service/src';
import rest from '@exchange/libs/utils/rest/src';

const URL = '/orders';

/* eslint-disable camelcase */
interface OrderHistoryQueryParams {
  from?: string;
  to?: string;
  instrument_code?: string;
  with_cancelled_and_rejected?: boolean;
  with_just_filled_inactive?: boolean;
  max_page_size?: string;
  cursor?: string;
}

interface OrderHistorySnapshot {
  max_page_size?: number;
  cursor?: string;
  order_history: Array<OrderTradesPair>;
}
/* eslint-enable camelcase */

export default function OrdersResource({ base, accountUrl }) {
  const orderResourceUrl = `${accountUrl}${URL}`;

  return {
    async getAll(payload: OrderHistoryQueryParams) {
      const res: OrderHistorySnapshot = await rest.request({
        url: orderResourceUrl,
        params: payload,
        base,
      });

      return res;
    },

    async get(id: string): Promise<OrderTradesPair> {
      const res = await rest.request({
        url: `${orderResourceUrl}/${id}`,
        base,
      });

      return res;
    },

    create(payload: OrderFormPayload) {
      return rest.request({
        method: 'post',
        payload,
        url: orderResourceUrl,
        base,
      });
    },

    delete(id: string) {
      return rest.request({
        method: 'delete',
        url: `${orderResourceUrl}/${id}`,
        base,
      });
    },

    deleteAll() {
      return rest.request({
        method: 'delete',
        url: orderResourceUrl,
        base,
      });
    },

    async getTrades(orderID: string, cursor?: string | null | undefined): Promise<{ max_page_size?: number; cursor?: string; trade_history: Array<BETradeHistoryEntry> }> {
      const res = await rest.request({
        url: `${orderResourceUrl}/${orderID}/trades`,
        params: {
          max_page_size: 100,
          ...(cursor && { cursor }),
        },
        base,
      });

      return res;
    },
  };
}
