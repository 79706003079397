import { type HolderDetails } from '@exchange/libs/transactions/funds/service/src';
import rest from '@exchange/libs/utils/rest/src';

const DETAILS_URL = '/details';

export default function DetailsResource({ base, fundingURL, accountHolderUrl }) {
  return {
    get(): Promise<HolderDetails> {
      return rest.request({
        url: `${fundingURL}${accountHolderUrl}${DETAILS_URL}`,
        base,
      });
    },
  };
}
