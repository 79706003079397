<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import { getFundingError } from './get-funding-error';

const props = defineProps({
  error: { type: [String, Object] as PropType<string | unknown>, required: true },
  isCode: { type: Boolean, default: true },
  isFunds: { type: Boolean, default: false },
  isTranslatedMessage: { type: Boolean, default: false },
});
const { t, te } = useI18n({ useScope: 'global' });

const errorMessage = computed(() => {
  if (props.isTranslatedMessage) {
    return props.error;
  }

  if (props.isFunds) {
    const er = getFundingError(props.error);

    return te(`modules.funds.api.errors.${er}`) ? t(`modules.funds.api.errors.${er}`) : t('modules.funds.api.errors.UNKNOWN');
  }

  if (props.isCode && typeof props.error === 'string') {
    return te(props.error) ? t(props.error) : t('modules.transactions.errors.unknown', { error: props.error.replace('modules.transactions.errors.', '') });
  }

  return props.error;
});
</script>

<template>
  <p
    class="modal-error"
    testid="modal-error"
  >
    {{ errorMessage }}
  </p>
</template>

<style lang="scss" scoped>
.modal-error {
  /** be aware of the predefined styles in modal-content */
  padding: 10px;
  border-radius: var(--app-border-radius);
  background-color: rgb(var(--v-theme-error));
  color: rgb(var(--v-theme-text-primary));
  white-space: normal;
}
</style>
