<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import { MarketState } from '@exchange/libs/market/service/src';

export interface TextObjFromLokalise {
  SUSPENDED?: string | { href: string; text: string };
  IN_MAINTENANCE?: string | { href: string; text: string };
  NEW?: string | { href: string; text: string };
  POST_ONLY?: string;
}

const props = defineProps({
  marketName: { type: String, required: false },
  marketState: { type: String as PropType<MarketState>, required: false },
});

const { t } = useI18n({ useScope: 'global' });

const getTextFrom = (property: TextObjFromLokalise, marketState: MarketState | undefined) => {
  if (!marketState) {
    return undefined;
  }

  switch (marketState) {
    case MarketState.SUSPENDED:
      return property.SUSPENDED;
    case MarketState.IN_MAINTENANCE:
      return property.IN_MAINTENANCE;
    case MarketState.NEW:
      return property.NEW;
    case MarketState.POST_ONLY:
      return property.POST_ONLY;
    default:
      return undefined;
  }
};

const ribbonTexts = computed(() => ({
  SUSPENDED: props.marketName ? t('modules.market.suspended.ribbon.text', { market: props.marketName }) : '',
  IN_MAINTENANCE: props.marketName ? t('modules.market.inMaintenance.ribbon.text', { market: props.marketName }) : '',
  NEW: undefined,
}));
const ribbonText = computed(() => getTextFrom(ribbonTexts.value, props.marketState));
</script>

<template>
  <div class="market-ribbon">
    <div class="market-ribbon__status">{{ ribbonText }}</div>
  </div>
</template>

<style lang="scss">
.market-ribbon {
  display: flex;
  flex: 0 0 auto;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: rgb(var(--v-theme-warning));
  color: var(--text-0);
  text-align: center;

  &__status {
    margin-right: 4px;
    color: var(--text-0);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
  }
}
</style>
