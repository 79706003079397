import type { OrderbookUpdateChange, OrderbookUpdateOperations } from './orderbook-model/interfaces';

interface OrderbookChanges {
  BUY: { [key: number]: OrderbookUpdateChange };
  SELL: { [key: number]: OrderbookUpdateChange };
}

export class UpdatesBuffer {
  public changes: OrderbookChanges = { BUY: {}, SELL: {} };

  public timerRunning = false;

  public time?: number;

  public callback: (ch: OrderbookChanges) => void;

  constructor(callback: (ch: OrderbookChanges) => void, time: number) {
    this.time = time;
    this.callback = callback;
  }

  public add(side: OrderbookUpdateOperations, price: string, size: string): void {
    if (!this.timerRunning) {
      this.startTimeout();
    }

    this.changes[side][price] = [side, price, size];
  }

  public startTimeout(): void {
    this.timerRunning = true;
    setTimeout(() => this.flush(), this.time);
  }

  public flush(): void {
    this.timerRunning = false;
    this.callback(this.changes);
    this.changes = { BUY: {}, SELL: {} };
  }

  public destroy() {
    this.callback = () => {};
    this.flush();
  }
}
