<script lang="ts" setup>
import { isEmpty } from 'lodash/fp';
import { nanoid } from 'nanoid';
import { computed, ref, type ShallowRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import type { RouteLocationNormalized } from 'vue-router';

import { authService } from '@exchange/libs/auth/service/src';
import { capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';
import { checkIsBiometricRoute, getNoAuthRequiredForRoute } from '@exchange/routing';

import { modalVariant } from './modal-type-validators';
import modalService, { type ModalToShow, type ModalToShowOptions } from './modals.service';

const PREFIX = 'modal-';

function getComponentProp<T>(comp: ShallowRef, prop: string, defaultVal: T): T {
  return comp[prop] || defaultVal;
}

const modalStack = ref<Array<ModalToShow>>([]);
const container = ref<HTMLElement>();
const centeredModal = ref(false);
const variant = ref(modalVariant.dark);

const route = useRoute();
const router = useRouter();

const isNativePlatform = capIsNativePlatform();

const currentModal = computed(() => (modalStack.value.length ? modalStack.value[modalStack.value.length - 1] : undefined));

const shouldHideCurrentModal = computed(() => currentModal.value && currentModal.value.isPrivate && authService.initialLoadAuthIsChecked && !authService.hasBeenAuthenticated);

const isBiometricRoute = computed(() => checkIsBiometricRoute(route));

const add = ({ component, props, listeners, compOptions }) => {
  const key = `${PREFIX}${nanoid()}`;
  const privateComponent = getComponentProp<boolean>(component, 'isPrivate', compOptions.isPrivate);
  const canCloseComponent = getComponentProp<boolean>(component, 'canClose', compOptions.canClose);

  modalStack.value = [
    ...modalStack.value,
    {
      key,
      component,
      props,
      listeners,
      isPrivate: privateComponent,
      canClose: canCloseComponent,
    },
  ];

  centeredModal.value = compOptions.centered;
  variant.value = compOptions.variant ?? modalVariant.dark;

  return key;
};

const defaultModalToShowOption: ModalToShowOptions = {
  isPrivate: false,
  canClose: true,
  variant: modalVariant.dark,
  centered: false,
};

const show = (component: ShallowRef, props = {}, listeners = {}, compOptions: ModalToShowOptions = defaultModalToShowOption) =>
  add({
    component,
    props,
    listeners,
    compOptions,
  });

const substitute = (component: ShallowRef, props = {}, listeners = {}, compOptions: ModalToShowOptions = defaultModalToShowOption) => {
  modalStack.value.pop();

  return add({
    component,
    props,
    listeners,
    compOptions,
  });
};

const hide = (modalKey?: string) => {
  if (modalKey) {
    modalStack.value = modalStack.value.filter(({ key }) => key !== modalKey);
  } else {
    modalStack.value = modalStack.value.slice(0, -1);
  }
};

const clear = () => {
  modalStack.value = [];
};

const outsideClick = () => {
  /**
   * Handles click events outside an element.
   * If modal dialog component - `currentModal` - has `modals-container-on-outside-click` listener it will be called before closing the modal dialog.
   *
   */

  if (
    currentModal.value &&
    currentModal.value.listeners &&
    currentModal.value.listeners['modals-container-on-outside-click'] &&
    typeof currentModal.value.listeners['modals-container-on-outside-click'] === 'function'
  ) {
    currentModal.value.listeners['modals-container-on-outside-click']();
  }

  hide();
};

const onMouseDown = (e: MouseEvent) => {
  if (currentModal.value?.canClose && container.value && e.target === container.value) {
    outsideClick();
  }
};

const announceModalClosed = (name: string | undefined) => {
  const event = new CustomEvent(`modal-closed:${name}`);

  container.value?.dispatchEvent(event);
};

watch(shouldHideCurrentModal, (value) => {
  if (value) {
    hide(currentModal.value?.key);
  }
});

watch(modalStack, () => {
  const { length } = modalStack.value;

  if (length === 0 && !isEmpty(route.hash)) {
    router.push({
      name: route.name as string,
      query: route.query,
      params: route.params,
    });
  }
});

watch(
  () => route.hash,
  async (newHash, oldHash) => {
    const wasShown = modalService.registeredModals.find((m) => `#${m.name}` === oldHash);
    const modalToShow = modalService.registeredModals.find((m) => `#${m.name}` === newHash);
    const currentModalKey = currentModal.value ? currentModal.value.key : undefined;

    if (!newHash && wasShown) {
      announceModalClosed(oldHash);

      // hash was removed but modal that was opened with hash is still shown
      if (currentModalKey) {
        hide(currentModalKey);

        return;
      }
    }

    // modal based on provided hash was not found
    if (!modalToShow) {
      return;
    }

    // if direct link was used we need to wait for router middleware check
    if (!authService.initialLoadAuthIsChecked) {
      await authService.waitForAuthIsChecked();
    }

    const noAuthRequired = getNoAuthRequiredForRoute({
      ...route,
      // both - the route, and modal need to be Public
      meta: { isPublic: !modalToShow.options?.isPrivate && route.meta?.isPublic },
    } as RouteLocationNormalized);

    if (!(await authService.checkRouteAccess({ noAuthRequired, isNativePlatform, trackingId: `ModalsContainer_${newHash}` }))) {
      // auth redirect will take us to login
      return;
    }

    // modal was fond and router access was granted
    show(
      modalToShow.component,
      {},
      {},
      {
        isPrivate: Boolean(modalToShow.options?.isPrivate),
        canClose: Boolean(modalToShow.options?.canClose),
        variant: modalVariant.dark,
      },
    );
  },
  { deep: false, immediate: true },
);

defineExpose({
  clear,
  hide,
  show,
  substitute,
});
</script>

<template>
  <div
    v-if="!isBiometricRoute"
    ref="container"
    :class="['modals-container', `modals-container--${variant}`, { 'modals-container--click-through': !currentModal, 'modals-container--centered': centeredModal }]"
    @mousedown="onMouseDown"
  >
    <transition
      name="modals-fade"
      mode="out-in"
    >
      <div
        v-if="currentModal"
        id="modals-container-scroll-helper"
        class="modals-container__status-bar-wrapper"
      >
        <keep-alive>
          <component
            :is="currentModal.component"
            :key="currentModal.key"
            class="modals-container__status-bar-modal-content"
            v-bind="currentModal.props"
            v-on="currentModal.listeners"
          />
        </keep-alive>
        <div class="modals-container__status-bar-safe-area" />
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
.modals-container {
  --modals-container-overlay: rgb(var(--v-theme-overlay-3));
  --modals-container-overlay-opacity: var(--v-overlay-3);

  position: absolute;
  z-index: var(--modal-z-index);
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  align-items: flex-start;
  justify-content: center;
  padding: 5% 0;
  overflow-y: auto;

  .xs & {
    padding: 0;
    overflow-y: hidden;
  }

  &::before {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--modals-container-overlay);
    content: '';
    opacity: var(--modals-container-overlay-opacity);
    transition: background-color 0.3s ease-out;
  }

  &__status-bar-wrapper {
    z-index: var(--modal-z-index);
  }

  &--click-through {
    pointer-events: none;

    &::before {
      background-color: transparent;
      pointer-events: none;
    }
  }
}

.modals-container--dark {
  --modals-container-overlay: rgb(var(--v-theme-overlay-2));
  --modals-container-overlay-opacity: var(--v-overlay-2);
}

.modals-container--centered {
  align-items: center;
}

.xs .modals-container__status-bar {
  &-wrapper {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-areas:
      'safe-area'
      'modal-grid';
    grid-template-rows: var(--safe-area-top) min-content;
    overflow-y: auto;
  }

  &-safe-area {
    position: sticky;
    z-index: 1;
    top: 0;
    width: 100%;
    height: var(--safe-area-top);
    background-color: rgb(var(--v-theme-elevation-1));
    grid-area: safe-area;
  }

  &-modal-content {
    grid-area: modal-grid;
  }
}

.modals-fade-enter-active {
  animation: modals-enter 0.3s ease-out;
}

.modals-fade-leave-active {
  animation: modals-enter 0.3s ease-out reverse;
}

@keyframes modals-enter {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
