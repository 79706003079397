import { type FundingTransferMethodType, type BEDepositDetailsResponse } from '@exchange/libs/transactions/funds/service/src';
import rest from '@exchange/libs/utils/rest/src';

const DETAILS_URL = '/details';

export default function DetailsResource({ base, url }) {
  return {
    get(currency: string, transferMethodType: FundingTransferMethodType): Promise<BEDepositDetailsResponse> {
      return rest.request({
        url: `${url}${DETAILS_URL}/${transferMethodType}/${currency}`,
        base,
      });
    },
  };
}
