<script lang="ts" setup>
import { type PropType } from 'vue';

import { type ModalVariant } from '@exchange/libs/modals/src';

defineProps({
  actionSecondary: { type: String, required: false },
  actionSecondaryTestId: { type: String, default: 'info-modal-action-secondary' },
  action: { type: String, required: true },
  actionTestId: { type: String, default: 'info-modal-action' },
  contentTestId: { type: String, default: 'info-modal-content' },
  message: { type: String, required: false },
  title: { type: String, required: true },
  variant: { type: String as PropType<ModalVariant>, default: 'light' },
  iconName: { type: String as PropType<'tick-circle-bulk' | 'refresh-2' | 'close-circle-bulk'>, default: 'tick-circle-bulk' },
  noIcon: { type: Boolean, default: false },
  isError: { type: Boolean, default: false },
});
const emit = defineEmits<{
  (e: 'action-clicked'): void;
  (e: 'action-secondary-clicked'): void;
}>();
</script>

<template>
  <x-modal
    class="info-modal"
    footer-variant="centered"
    :can-close="false"
    :variant="variant"
  >
    <template #content>
      <div
        class="info-modal__content"
        :testid="contentTestId"
      >
        <ot-icon
          v-if="!noIcon"
          class="info-modal__status-icon"
          :class="{ 'info-modal__status-icon--error': isError }"
          :name="iconName"
        />
        <h3 class="info-modal__title">{{ $t(title) }}</h3>
        <p
          v-if="message"
          class="info-modal__message"
        >
          {{ $t(message) }}
        </p>
        <slot
          v-else
          name="content"
        />
      </div>
    </template>
    <template #buttons="{ itemClass }">
      <x-button
        v-if="actionSecondary"
        class="info-modal__action info-modal__action--secondary"
        variant="dark-outline"
        :testid="actionSecondaryTestId"
        :text="$t(actionSecondary)"
        :class="itemClass"
        @click="emit('action-secondary-clicked')"
      />
      <x-button
        v-if="action"
        class="info-modal__action"
        variant="primary"
        :testid="actionTestId"
        :text="$t(action)"
        :class="itemClass"
        @click="emit('action-clicked')"
      />
    </template>
  </x-modal>
</template>

<style lang="scss">
.info-modal {
  &__title {
    text-align: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__status-icon {
    position: relative;
    display: flex;
    width: 107px;
    height: 107px;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    color: rgb(var(--v-theme-primary));

    &--error {
      color: rgb(var(--v-theme-secondary));
    }

    &::before {
      position: absolute;
      border-radius: 50%;
      background-color: rgb(var(--v-theme-primary));
      content: '';
      inset: 0;
      opacity: 0.3;
    }

    &--error::before {
      background-color: rgb(var(--v-theme-secondary));
    }

    &.ot-icon svg {
      width: 80%;
    }

    .svg-icon__content {
      fill: var(--text-0);
    }

    .svg-icon--tick-circle-bulk {
      z-index: 1;
      width: 90px;
      height: 90px;
    }
  }

  &__message {
    padding: 0 8%;
    text-align: center;
  }
}
</style>
