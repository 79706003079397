// eslint-disable-next-line @typescript-eslint/naming-convention
export enum modalType {
  'base' = 'base',
  'info' = 'info',
  'xs-fullscreen' = 'xs-fullscreen',
}

export type ModalType = keyof typeof modalType;

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum modalVariant {
  'light' = 'light',
  'dark' = 'dark',
}
export type ModalVariant = keyof typeof modalVariant;

export enum ModalFooterVariant {
  'centered' = 'centered',
  'default' = 'default',
}
export type ModalFooterVariantType = keyof typeof ModalFooterVariant;
