<script lang="ts" setup>
import { type PropType, ref, onMounted, computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { ModalVariant } from '@exchange/libs/modals/src';
import CryptoInfoRest from '@exchange/libs/rest-api/crypto-info-api';
import PersonalRest from '@exchange/libs/rest-api/personal-api';
import PublicRest from '@exchange/libs/rest-api/public-api';

const props = defineProps({
  variant: { type: String as PropType<ModalVariant>, required: true },
  transactionType: { type: String as PropType<'deposit' | 'withdraw'>, required: true },
});

const { t } = useI18n({ useScope: 'global' });

interface Jurisdiction {
  code: string;
  name: string;
  noFiatTransfers?: boolean;
}

const jurisdictions = ref<Record<string, Jurisdiction>>({});
const selectedJurisdiction = ref<Jurisdiction | null>(null);

const emit = defineEmits<{
  (e: 'action-clicked', jurisdiction: string): void;
}>();

const jurisdictionOptions = computed(() =>
  Object.entries(jurisdictions.value).map(([code, data]) => ({
    code,
    name: data.name,
    ...data,
  })),
);

onMounted(async () => {
  jurisdictions.value = await PublicRest.Jurisdictions.get();

  try {
    const geoInfo = await CryptoInfoRest.GeoInfo.get();
    const userCountry = geoInfo.isoCode;
    const matchingJurisdiction = jurisdictionOptions.value.find((j) => j.code.toLowerCase() === userCountry.toLowerCase());

    if (matchingJurisdiction) {
      selectedJurisdiction.value = matchingJurisdiction;
    }
  } catch (error) {
    // console.error('Failed to fetch geo location:', error);
  }
});

const handleActionClick = async () => {
  if (selectedJurisdiction.value) {
    try {
      await PersonalRest.Identity.update({
        addressOfResidency: {
          country: selectedJurisdiction.value.code,
        },
      });
      emit('action-clicked', selectedJurisdiction.value.code);
    } catch (error) {
      // console.error('Failed to update jurisdiction:', error);
    }
  }
};
</script>

<template>
  <x-modal
    class="info-modal"
    footer-variant="centered"
    :can-close="false"
    :variant="variant"
  >
    <template #content>
      <div class="info-modal__content">
        <h3 class="info-modal__title">
          {{ $t(`modules.transactions.withdraw.confirmJurisdictionModal.title`) }}
        </h3>
        <p class="info-modal__message">
          {{
            transactionType === 'withdraw'
              ? $t(`modules.transactions.withdraw.confirmJurisdictionModal.withdrawMessage`)
              : $t(`modules.transactions.withdraw.confirmJurisdictionModal.depositMessage`)
          }}
        </p>

        <!-- Add jurisdiction selector -->
        <ot-select
          class="info-modal__jurisdiction-select"
          :value="selectedJurisdiction"
          :options="jurisdictionOptions"
          label="name"
          track-by="code"
          :searchable="true"
          @update:value="selectedJurisdiction = $event"
        >
          <!-- Add template for selected value -->
          <template #selected-option="selected">
            <div class="jurisdiction-selection__info">
              <img
                :src="`https://flagcdn.com/${selected.code.toLowerCase()}.svg`"
                :alt="`${selected.name} flag`"
                class="jurisdiction-flag"
              />
              <div class="jurisdiction-selection__name">{{ selected.name }}</div>
            </div>
          </template>

          <!-- Template for dropdown options -->
          <template #option="option">
            <x-list-item
              :name="`jurisdiction-option-${option.code}`"
              :active="selectedJurisdiction?.code === option.code"
              :title="option.name"
            >
              <template #prefix>
                <img
                  :src="`https://flagcdn.com/${option.code.toLowerCase()}.svg`"
                  :alt="`${option.name} flag`"
                  class="jurisdiction-flag"
                />
              </template>
            </x-list-item>
          </template>
        </ot-select>
      </div>
    </template>
    <template #buttons="{ itemClass }">
      <x-button
        class="info-modal__action"
        variant="primary"
        :disabled="!selectedJurisdiction"
        :text="$t('modules.transactions.withdraw.confirmJurisdictionModal.action')"
        :class="itemClass"
        @click="handleActionClick"
      />
    </template>
  </x-modal>
</template>

<style lang="scss" scoped>
/* stylelint-disable */

.info-modal {
  &__jurisdiction-select {
    margin-top: 16px;
    width: 100%;

    :deep(.multiselect) {
      min-height: 44px;
    }

    :deep(.multiselect__select) {
      height: 44px;
    }

    :deep(.multiselect__tags) {
      min-height: 44px;
      padding: 8px 40px 8px 12px;
      border-radius: var(--app-border-radius);
    }

    :deep(.multiselect__content-wrapper) {
      width: 100%;
      min-width: 300px;
    }

    :deep(.multiselect__option) {
      padding: 12px 16px;
    }

    :deep(.multiselect__dropdown) {
      width: 100%;
    }
  }

  &__content {
    min-width: 400px;
    max-width: 500px;
  }
}

.jurisdiction-selection {
  &__info {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__name {
    color: rgb(var(--v-theme-text-primary));
    font-weight: var(--font-weight-medium);
  }
}

.jurisdiction-flag {
  width: 24px;
  height: 16px;
  border-radius: 2px;
  object-fit: cover;
}
</style>
