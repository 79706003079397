import rest from '@exchange/libs/utils/rest/src';

const URL = '/trades';

interface Currency {
  code: string;
  precision: number;
}
export interface BEEotcTrade {
  account_holder_id: string;
  account_id: string;
  amount: string;
  details: {
    cumAmt: string;
    avgPx: string;
  };
  funding_currency: Currency;
  status: string;
  time: string;
  traded_symbol: {
    base: Currency;
    quote: Currency;
  };
  transaction_id: string;
  type: string;
}

interface BEEotcTradeResponse {
  account_holder_id: string;
  cursor: string;
  max_page_size: number;
  transactions: Array<BEEotcTrade>;
}

interface OutgoingGetAllPayload {
  cursor?: string;
}

export default function Resource({ base }) {
  return {
    getAll(payload: OutgoingGetAllPayload): Promise<BEEotcTradeResponse> {
      return rest.request({
        url: URL,
        base,
        params: {
          ...payload,
          page_size: '20',
        },
      });
    },
  };
}
