import QuestionnaireResource from './questionnaire-resource';

const URL = '/customer';

export default function Resources({ base }) {
  const customerUrl = `${base}${URL}`;

  return {
    Questionnaire: QuestionnaireResource({ base: customerUrl }),
  };
}
