import type { BETradeHistoryEntry } from '@exchange/libs/trade/trade-history/service/src';
import rest from '@exchange/libs/utils/rest/src';

const URL = '/trades';

/* eslint-disable camelcase */
export interface TradeHistorySnapshot {
  max_page_size?: number;
  cursor?: string;
  trade_history: Array<BETradeHistoryEntry>;
}

export interface TradeHistoryQuerryParams {
  from: string;
  to: string;
  instrument_code?: string;
  max_page_size?: string;
  cursor?: string;
}
/* eslint-enable camelcase */

export default function TradesResource({ base, accountUrl }) {
  return {
    async get(payload: TradeHistoryQuerryParams) {
      const res: TradeHistorySnapshot = await rest.request({
        url: `${accountUrl}${URL}`,
        params: {
          ...payload,
          max_page_size: '20',
        },
        base,
      });

      return res;
    },
  };
}
