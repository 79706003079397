<script lang="ts" setup>
const emit = defineEmits<{ (e: 'close-market-promotion'): void }>();
</script>

<template>
  <div class="market-promotion-ribbon">
    <p class="market-promotion-ribbon__body">
      <i18n-t
        keypath="modules.ribbons.marketPromotion.message.text"
        tag="div"
        scope="global"
      >
        <template #linkText>
          <x-link-external :href="$t('modules.ribbons.marketPromotion.message.linkHref')">
            {{ $t('modules.ribbons.marketPromotion.message.linkText') }}
          </x-link-external>
        </template>
      </i18n-t>
    </p>
    <button
      class="market-promotion-ribbon__close"
      @click="emit('close-market-promotion')"
    >
      <ot-icon name="close"></ot-icon>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.market-promotion-ribbon {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  background-color: rgb(var(--v-theme-primary));
  color: var(--text-0);

  &__body {
    color: var(--text-0);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);

    .sm &,
    .xs & {
      text-align: center;
    }

    .sm & {
      margin: 0 50px 0 20px;
    }

    .xs & {
      margin: 0 40px 0 10px;
    }
  }

  &__close {
    position: absolute;
    right: 20px;
    min-width: 24px;
    flex: 0 1 auto;
    color: var(--text-0);
  }
}
</style>
