import { BEJurisdictionModel } from '@exchange/libs/jurisdiction/jurisdiction-model';
import rest from '@exchange/libs/utils/rest/src';

const URL = '/countries';

export default function JurisdictionsResource({ base }) {
  return {
    get(): Promise<Record<string, BEJurisdictionModel>> {
      return rest.unAuthorizedRequest({
        url: URL,
        base,
      });
    },
  };
}
