import rest from '@exchange/libs/utils/rest/src';

const URL = '/notification';

export enum NotificationKey {
  JURISDICTION_OPENING = 'jurisdiction-opening',
}

type BaseNotificationSchema = {
  email: string;
};

type NotificationSchemas = {
  [NotificationKey.JURISDICTION_OPENING]: BaseNotificationSchema & {
    residencyCountry: string;
  };
};

export type NotificationBody<K extends NotificationKey> = K extends keyof NotificationSchemas ? NotificationSchemas[K] : BaseNotificationSchema;

export default function Resource({ base }) {
  return {
    post<K extends NotificationKey>(key: K, data: NotificationBody<K>): Promise<void> {
      return rest.request({
        url: `${URL}/${key}`,
        base,
        method: 'POST',
        payload: data,
      });
    },
  };
}
