import { type IncomingCurrency } from '@exchange/libs/utils/currency/src';
import rest from '@exchange/libs/utils/rest/src';

const URL = '/currencies';

enum CurrencyType {
  CRYPTO = 'crypto',
  FIAT = 'fiat',
}

export default function CurrenciesResource({ base }) {
  return {
    get(type?: CurrencyType): Promise<Array<IncomingCurrency>> {
      return rest.unAuthorizedRequest({
        url: URL,
        query: {
          type,
        },
        base,
      });
    },
  };
}
