import { computed, Ref } from 'vue';

import { ordersService } from '@exchange/libs/order/my-orders/service/src';
import { OrderSide, OrderType, MyOrdersListType, MyOrdersTabs, OrderStatus, TimeInForceType } from '@exchange/libs/order/shared-model/src/lib/order-essentials';
import type { OrderWithTradesModel } from '@exchange/libs/order/shared-model/src/lib/order-model';

import type { Orderbook } from './orderbook-model';
import { OrderbookSide } from './orderbook-model/interfaces';
import { calculateAggregation } from './orderbook-model/util';

export interface OrderbookMyOrderIndicator {
  type: OrderType;
  status: OrderStatus;
  price: number;
  originalPrice: number;
  side: OrderSide;
  obSides: Array<OrderbookSide>;
}

export interface OrderbookMyOrdersIndicators {
  orders: Array<OrderbookMyOrderIndicator>;
  triggers: Array<OrderbookMyOrderIndicator>;
}

type OrderWithPrice = OrderWithTradesModel & { price: number };
type Trigger = OrderWithPrice & { triggerPrice: number };

function isTrigger(order: OrderbookMyOrderIndicator | OrderWithTradesModel): order is Trigger {
  return order.type === OrderType.STOP && order.status === OrderStatus.OPEN;
}

function isBooked(order: OrderbookMyOrderIndicator | OrderWithTradesModel): order is OrderWithPrice {
  /*
  return (order.type === OrderType.LIMIT || (order.type === OrderType.STOP && order.status !== OrderStatus.OPEN)) && order.status !== OrderStatus.CLOSING;
  */

  return order.status !== OrderStatus.CLOSING;
}

const getObSides = ({ type, side, askPrice, bidPrice, price }: { type: OrderType; side: OrderSide; askPrice: number; bidPrice: number; price: number }) => {
  if (type !== OrderType.STOP) {
    return side === OrderSide.BUY ? [OrderbookSide.BID] : [OrderbookSide.ASK];
  }

  if (price <= bidPrice) {
    return [OrderbookSide.BID];
  }

  if (price >= askPrice) {
    return [OrderbookSide.ASK];
  }

  return [OrderbookSide.ASK, OrderbookSide.BID];
};

export function hideIndicatorBasedOnTimeInForceType(timeInForce?: TimeInForceType) {
  return timeInForce === TimeInForceType.IMMEDIATE_OR_CANCELLED || timeInForce === TimeInForceType.FILL_OR_KILL;
}

/**
 * https://bitpanda.atlassian.net/wiki/spaces/BPRO/pages/1623163156/Green+dots+logic+in+the+order+book+and+depth+chart
 */
export function useMyOrdersIndicators({ orderbook, marketId }: { orderbook: Ref<Orderbook | undefined>; marketId: Ref<string> }) {
  const myOrderIndicators = computed(() => {
    if (!orderbook.value || orderbook.value.currentAggregationLevel === undefined || !marketId.value) {
      return {
        triggers: [],
        orders: [],
      };
    }

    const { currentAggregationLevel, asks, bids } = orderbook.value;

    const activeOrders = Object.values(ordersService.reactiveData[MyOrdersListType.open].value)
      .map(({ counter, orderId }) => {
        const makeVueAware = Number(counter >= 0);

        return ordersService.getOrderById(orderId, MyOrdersListType.open) || makeVueAware;
      })
      .filter((v) => {
        if (typeof v === 'number') {
          return false;
        } /** should never happen */

        return !hideIndicatorBasedOnTimeInForceType(v.timeInForce);
      }) as Array<OrderWithTradesModel>;

    const allOrders = activeOrders.reduce(
      (acc: { triggers: Array<OrderbookMyOrderIndicator>; orders: Array<OrderbookMyOrderIndicator> }, order: OrderWithTradesModel) => {
        const orderOk = !!order.price && order.showAs === MyOrdersTabs.ACTIVE && order.delayedRemove === false && marketId.value === order.instrumentCode;

        if (!orderOk) {
          return acc;
        }

        const isTr = isTrigger(order);
        const isBk = isBooked(order);
        const orderPrice = order.price || 0;

        const price = isTr
          ? order.triggerPrice
          : calculateAggregation({
              price: orderPrice,
              side: order.side === OrderSide.BUY ? OrderbookSide.BID : OrderbookSide.ASK,
              aggregationLevel: currentAggregationLevel || 0,
            });

        const value = {
          type: order.type,
          status: order.status,
          price,
          originalPrice: isTr ? order.triggerPrice : orderPrice,
          side: order.side,
          obSides: getObSides({
            type: order.type,
            side: order.side,
            askPrice: asks[0]?.price || 0,
            bidPrice: bids[0]?.price || 0,
            price,
          }),
        };

        if (isTr) {
          acc.triggers.push(value);
        } else if (isBk) {
          acc.orders.push(value);
        }

        return acc;
      },
      { triggers: [], orders: [] },
    );

    return allOrders;
  });

  return {
    myOrderIndicators,
  };
}
