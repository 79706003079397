import rest from '@exchange/libs/utils/rest/src';

const URL = '/blockpit';

export default function BlockpitResource({ base, userUrl }) {
  const blockpitUrl = `${userUrl}${URL}`;

  return {
    async getLink(): Promise<string> {
      const {
        data: {
          attributes: { value: link },
        },
      } = await rest.request(
        {
          url: `${blockpitUrl}/link`,
          base,
        },
        {
          useSubaccount: false,
        },
      );

      return link;
    },
  };
}
