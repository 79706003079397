import { computed, ref, Ref, watch } from 'vue';

import { throttleWithOptions } from '@exchange/helpers/lodash-fp-no-cap';
import { launchdarkly } from '@exchange/libs/utils/launchdarkly/src';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';
import { type SubscribeCallbacks } from '@exchange/libs/utils/wss/src';
import { WSIncomingEventTypes } from '@exchange/libs/utils/wss/src/lib/websocket-spot-model';

import { Orderbook } from './orderbook-model';
import { OrderbookUpdate } from './orderbook-model/interfaces';
import type { OrderbookSnapshot, OrderbookUpdateChange } from './orderbook-model/interfaces';
import orderbookChannel from './Orderbook.channel';
import { UpdatesBuffer } from './updates-buffer-model';

const useSpreadGuard = (orderbook: Ref<Orderbook | undefined>, restartOrderbooks: () => Promise<void>, logPrefix: string) => {
  const resubscribeIfNegativeSpread = (spread: number) => {
    if (spread < 0) {
      logger.error(`${logPrefix} negative spread`, spread);
      restartOrderbooks();
    }
  };
  const throttledResubscribeIfNegativeSpread = throttleWithOptions(60000, resubscribeIfNegativeSpread, { leading: true, trailing: false });

  return watch(() => orderbook.value?.spread, throttledResubscribeIfNegativeSpread);
};

interface OrderbookData {
  orderbook: Ref<Orderbook | undefined>;
  listenerCount: number;
  unsubscribeTimer: number | undefined;
  buffer: UpdatesBuffer | undefined;
  addedTime: Ref<number | undefined>;
  snapshotReceived: Ref<boolean>;
  unsubscribe?: () => Promise<void>;
}
export class OrderbookService {
  private readonly logPrefix = 'OrderbookService:';

  private data: Dictionary<OrderbookData> = {};

  public hasAnimations = computed(() => false);

  async restartOrderbooks() {
    try {
      logger.warn(`${this.logPrefix} restarting...`, this.data);
      Object.keys(this.data).forEach((marketId) => {
        logger.log(`${this.logPrefix} resetting for`, marketId);
        this.data[marketId]?.orderbook.value?.clearOrderbook();
      });
      await orderbookChannel.restart();
    } catch (e) {
      logger.error(`${this.logPrefix} restart failed with`, e);
      await retryService.waitForNextRetryTick();
      await this.restartOrderbooks();
    }
  }

  public getOrderbook = ({ id: marketId, precision }: { id: string; precision: number }) =>
    new Promise<Ref<Orderbook>>((r, f) => {
      if (this.data[marketId]?.orderbook && this.data[marketId]?.orderbook.value !== undefined) {
        r(this.data[marketId]?.orderbook as Ref<Orderbook>);
      }

      this.subscribe(
        { id: marketId, precision },
        {
          success: (res) => {
            watch(res.orderbook, (v) => {
              if (v !== undefined) {
                r(this.data[marketId]?.orderbook as Ref<Orderbook>);
              }
            });
          },
          fail: (error) => {
            f(error);
          },
        },
      );
    });

  private unsubscribe = (marketId: string) => () => {
    const marketData = this.data[marketId];

    if (!marketData) {
      logger.warn(`${this.logPrefix} trying to unsubscribe from ${marketId}, but there is already no data`);
      return;
    }

    marketData.listenerCount -= 1;

    if (marketData.listenerCount < 1) {
      const timer = window.setTimeout(async () => {
        // TODO ask @oli what he meant to do, without added catch the code can produce unhandled promise rejection!!!

        try {
          // if unsubscribe is undefined something went wrong - so i want it to fail here
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          await marketData.unsubscribe!()
            .then(() => {
              this.clear(marketId);
            })
            .catch((e) => {
              logger.log(`${this.logPrefix} unsubscribing for ${marketId} failed with`, e);
            });
        } catch (e) {
          logger.log(`${this.logPrefix} @oli, it's failed, you wanna check something now`, e);
        }
      }, 5000);

      marketData.unsubscribeTimer = timer;
    }
  };

  subscribe({ id: marketId, precision }: { id: string; precision: number }, callbacks: SubscribeCallbacks<{ orderbook; addedTime; snapshotReceived }>) {
    const getDefaultData = () => {
      const orderbook = ref(new Orderbook(precision));
      const addedTime = ref(new Date().getTime());

      useSpreadGuard(orderbook, () => this.restartOrderbooks(), this.logPrefix);
      const throttleWS = launchdarkly.flags['throttle-ws'].value;
      const buffer = new UpdatesBuffer(
        (chgs) =>
          this.addUpdate({
            changes: [...Object.values(chgs.BUY), ...Object.values(chgs.SELL)],
            marketId,
          }),
        throttleWS['order-book'] || 0,
      );

      return <OrderbookData>{
        listenerCount: 1,
        snapshotReceived: ref(false),
        unsubscribe: undefined,
        unsubscribeTimer: undefined,
        orderbook,
        addedTime,
        buffer,
      };
    };

    let marketData = this.data[marketId];

    if (!marketData) {
      marketData = getDefaultData();
      this.data[marketId] = marketData;

      marketData.unsubscribe = this.openChannel(marketId, {
        success: () => {
          let smd = this.data[marketId];

          if (!smd) {
            logger.warn(`${this.logPrefix} open channel succeeded but there is no ${marketId} data, setting defaults...`);
            smd = getDefaultData();
            this.data[marketId] = smd;
          }

          callbacks.success({
            orderbook: smd.orderbook,
            addedTime: smd.addedTime,
            snapshotReceived: smd.snapshotReceived,
          });
        },
        fail: (error) => {
          callbacks.fail(error);
        },
      });
    } else {
      marketData.listenerCount += 1;
      const { unsubscribeTimer } = marketData;

      if (unsubscribeTimer !== undefined) {
        clearTimeout(unsubscribeTimer);
        marketData.unsubscribeTimer = undefined;
      }

      callbacks.success({
        orderbook: marketData.orderbook,
        addedTime: marketData.addedTime,
        snapshotReceived: marketData.snapshotReceived,
      });
    }

    return this.unsubscribe(marketId);
  }

  openChannel(marketId: string, callbacks: SubscribeCallbacks) {
    return orderbookChannel.subscribe(
      {
        market: marketId,
        onMessage: (event) => {
          switch (event.type) {
            case WSIncomingEventTypes.ORDER_BOOK_SNAPSHOT:
              this.setSnapshot(event as OrderbookSnapshot);
              break;
            case WSIncomingEventTypes.ORDER_BOOK_UPDATE:
              this.throttledAddUpdate(event as OrderbookUpdate);
              break;
            default:
              logger.log(`${this.logPrefix} Unknown ORDER_BOOK channel event type`, event.type);
              break;
          }
        },
      },
      callbacks,
    );
  }

  async setSnapshot(payload: OrderbookSnapshot) {
    const marketData = this.data[payload.instrument_code];

    if (marketData) {
      marketData.orderbook.value?.setSnapshot(payload);
      marketData.snapshotReceived.value = true;
    } else {
      await retryService.waitForNextRetryTick();
      logger.warn('setSnapshot failed - retrying now', JSON.stringify(payload));
      await this.setSnapshot(payload);
    }
  }

  async addUpdate({ changes, marketId }: { changes: OrderbookUpdateChange[]; marketId: string }) {
    const orderbook = this.data[marketId]?.orderbook.value;
    const snapshotReceived = this.data[marketId]?.snapshotReceived.value;

    if (orderbook && snapshotReceived) {
      orderbook.addUpdate(changes);
    } else {
      logger.warn('addUpdate is waiting');

      await retryService.waitForNextRetryTick();
      logger.warn('addUpdate failed - retrying now', marketId, JSON.stringify(changes));
      await this.addUpdate({ changes, marketId });
    }
  }

  throttledAddUpdate({ changes, instrument_code: marketId }: OrderbookUpdate) {
    changes.forEach(([side, price, size]) => {
      if (!this.data[marketId]) {
        logger.warn(`${this.logPrefix} discarded update for ${marketId}`);
        return;
      }

      const { buffer } = this.data[marketId] || { buffer: undefined };

      if (buffer) {
        buffer.add(side, price, size);
      }
    });
  }

  private clear(marketId: string) {
    const orderbook = this.data[marketId]?.orderbook.value;

    if (orderbook) {
      orderbook.clearOrderbook();
      logger.info(`${this.logPrefix} deleting ${marketId} data`);
      this.data[marketId]?.buffer?.destroy();
      delete this.data[marketId];
    }
  }
}

const orderbookService = new OrderbookService();

export default orderbookService;
