import rest from '@exchange/libs/utils/rest/src';

const URL = '/time';

export default function TimeResource({ base }) {
  return {
    get(): Promise<{ epoch_millis: number }> {
      return rest.unAuthorizedRequest({
        url: URL,
        base,
      });
    },
  };
}
