import { type BESubaccount } from '@exchange/libs/trading-accounts/service/src/lib/models/subaccount-model';
import rest from '@exchange/libs/utils/rest/src';

import LimitsResource from './limits-resource';
import TransfersResource from './transfers-resource';

const URL = '/subaccounts';

/* eslint-disable camelcase */
export interface BECreateSubaccount {
  name: string;
}

export interface BECloseSubaccount {
  account_id: string;
}
/* eslint-enable camelcase */

export default function SubaccountsResource({ base, baseFast }) {
  return {
    async getAll(): Promise<{ subaccounts: Array<BESubaccount> }> {
      return rest.request(
        {
          url: URL,
          base: baseFast,
        },
        {
          useSubaccount: false,
        },
      );
    },
    async create(payload: BECreateSubaccount) {
      return rest.request(
        {
          method: 'post',
          payload,
          url: URL,
          base: baseFast,
        },
        {
          useSubaccount: false,
        },
      );
    },
    close(payload: BECloseSubaccount) {
      return rest.request(
        {
          method: 'post',
          payload,
          url: `${URL}/close`,
          base,
        },
        {
          useSubaccount: false,
        },
      );
    },
    Transfers: TransfersResource({ base: baseFast, subaccountUrl: URL }),
    Limits: LimitsResource({ base: baseFast, subaccountUrl: URL }),
  };
}
