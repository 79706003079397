import rest from '@exchange/libs/utils/rest/src';

const URL = '/transfers';

export interface BESubaccountTransferPostParams {
  source_account_id: string;
  destination_account_id: string;
  amount: string;
  currency: string;
}

export default function TransfersResource({ base, subaccountUrl }) {
  const transferUrl = `${subaccountUrl}${URL}`;

  return {
    post(payload: { amount: string; currency: string; source: string; destination: string }) {
      const bePayload: BESubaccountTransferPostParams = {
        amount: payload.amount,
        currency: payload.currency,
        source_account_id: payload.source,
        destination_account_id: payload.destination,
      };

      return rest.request(
        {
          url: transferUrl,
          payload: bePayload,
          method: 'post',
          base,
        },
        {
          useSubaccount: false,
        },
      );
    },
  };
}
